import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { NavDropdown, Nav } from 'react-bootstrap';

export const StyledLink = styled(Link)`
  ${css`
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0.5rem 1rem;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
  `}
`;

export const StyledHeaderLink = styled(Nav.Link)`
  ${css`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000000;
  `}
`;

export const StyledNavDropdown = styled(NavDropdown)`
  ${css`
    &&& {
      font-size: 16px;
      font-weight: 600 !important;
    }
  `}
`;