import React, { useState } from 'react';
import { Main } from './styled-websiteLookup';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import Upgrade from '../websiteLookupResult/upgrade/Upgrade';
import Accordion from 'react-bootstrap/Accordion';
import {Form} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';
import {extractWebsiteName} from "../utility/SearchWebsite";
import {Typeahead} from "react-bootstrap-typeahead";
import {Autocomplete} from "../Autocomplete";

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'Technology lookup', path: '/websitelookup'},
]

const WebsiteLookup = () => {
  const [error, setError] = useState('');
  const [inputValue, setInputValue] = useState('')
  const [websiteSuggestions, setWebsiteSuggestions] = useState([]);

  function searchWebsite(value: string) {
    try {
      setError('')
      const {website, error} = extractWebsiteName(value);
      if (error != null) {
        throw error;
      }
      const url = `/websitelookup/${website}`
      navigate(url)
    } catch (error) {
      console.error('Invalid URL:', error);
      setError('Please enter a valid URL.');
    }
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default Enter key behavior
      searchWebsite(inputValue);
    }
  };

  const handleSearch = () => {
    if (inputValue.length === 0) {
      setError('Please enter a valid URL.');
      return;
    }
    searchWebsite(inputValue)
  };

  const navigate = useNavigate()

  return ( 
    <Main>
      <div className='container websiteDetails' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
        <RouteCreator routes={routesInit} />
        <h1>Website Technology Lookup</h1>
        <p className='pageDescription'>Find out what websites are built with</p>

        <h3>Website Technology Lookup with Webrofiler</h3>
        <p className="lead">We offer comprehensive insights into website technical aspects. It uses advanced algorithms to scan and uncover valuable information about a website's foundation.</p>

        <h3 className="mt-5">Unravel the Website's Technological Backbone</h3>
        <p className="lead">Our website technology lookup service offers detailed information on hosting providers, CMS platforms, and content management systems, enabling informed decisions and a competitive edge in the digital landscape. It also assesses server stability, response times, and security concerns.</p>

        <h3 className="mt-5">Peek into Web Frameworks and Technologies</h3>
        <p className="lead">Identify website frameworks and technologies like PHP, Python, Ruby, React, Angular, and Vue.js for structure, interactivity, and analytics to measure traffic, user engagement, and site success.</p>

        <h3 className="mt-5">In-Depth Security Analysis</h3>
        <p className="lead">We understand website security measures, including SSL certificates and encryption protocols, to ensure data transmission and build trust with visitors in the digital age.</p>

        <h3 className="mt-5">Reliable and Trustworthy Information</h3>
        <p className="lead">Webrofiler is a trusted website technology lookup service offering accurate and dependable information. With a team of experts constantly updating its database and algorithms, it helps analyze competitors, optimize web presence, and satisfy curiosity. Try us today to unlock a world of knowledge about the digital landscape.</p>

        <Accordion defaultActiveKey="0" className="mt-5">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Look up a website</Accordion.Header>
            <Accordion.Body>
              <Form>
                <Form.Group controlId="websiteInput">
                  <div className='inputDiv' style={{maxHeight: "500px", height: "300px"}}>
                    <Autocomplete websiteOnly={true}/>

                    <Form.Control.Feedback type="invalid">
                    {error}
                  </Form.Control.Feedback>

                  </div>

                </Form.Group>
              </Form>

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Look up a list of websites</Accordion.Header>
            <Accordion.Body>
              <p className='uploadFileTitle'>Upload a CSV or TXT file with up to 100,000 URLs, each on a separate line.</p>
              <Form>
                <Form.Group controlId="websiteInput">
                  <div className='inputDiv'>

                  <Form.Control
                      value={inputValue}
                      onChange={({target: {value}})=>setInputValue(value)}
                      onKeyPress={handleKeyPress}
                      placeholder='Select a file to upload'
                      disabled={true}
                  />
                    <Search />
                    </div>
                </Form.Group>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Upgrade />
      </div>
    </Main>
  )
}

export default WebsiteLookup