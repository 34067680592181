import React, {useEffect, useState} from 'react';
import { TableDiv, Progress } from './styled-table';
import PlaceholderLoading from 'react-placeholder-loading';
import findPercentageMax from '../utils/findPercentageMax';
import SharedDataContext from "../../../contexts/SharedDataContext";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import {Doughnut} from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);
import authContext from "../../../contexts/AuthContext";
const Country = (technologyData:any) => {
    const user = React.useContext(authContext).user;
    const {countries} = React.useContext(SharedDataContext);

    const [countryData, setCountryData] = useState<any[]>([]); // Define the correct type for your countries data
    const [chartData, setChartData] = useState([]);
    const [chartLables, setChartLables] = useState([]);
    const getCountryDisplayName = (countryCode: string) => {
        const country = countries.find((country) => country['alpha-2'] === countryCode);
        return country ? country.name : countryCode;
    }
    useEffect(() => {
        if(!technologyData?.websitesSummary) return;
        setCountryData(technologyData?.websitesSummary)

        const chartData = technologyData?.websitesSummary.map((item: any) => item.count);
        const chartLables = technologyData?.websitesSummary.map((item: any) => getCountryDisplayName(item.country));
        setChartData(chartData);
        setChartLables(chartLables);
    }, [technologyData])

    const data = {
        labels: chartLables,
        datasets: [
            {
                label: "Market Share Percentage",
                data: chartData,
                backgroundColor: [
                    "#4169E1",
                    "#FF0000",
                    "#FFA500",
                    "#2E8B57",
                    "#8B008B",
                    "#87CEFA",
                    "#FF1493",
                    "#32CD32",
                    "#B22222",
                    "#4682B4",
                ],
                borderColor: [
                    "#4169E1",
                    "#FF0000",
                    "#FFA500",
                    "#2E8B57",
                    "#8B008B",
                    "#87CEFA",
                    "#FF1493",
                    "#32CD32",
                    "#B22222",
                    "#4682B4",
                ],
                borderWidth: 1,
            },
        ],
    };

    var options: any = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
                display: true,
            },
            title: {
                display: false,
                text: "Chart.js Line Chart",
            },
        },
    };

    if(!technologyData?.websitesSummary) return <></>

    return (
        <TableDiv>
            <div className='tableDiv'>

                {countryData.length == 0 ? (
                    <></>
                ) : (
                    <>
                        <div className="mb-3">
                        <h5>Country Statistics using {technologyData?.name}</h5>
                        </div>

                        <div className="myChart mt-5 mb-5">
                            <Doughnut data={data} options={options} />
                        </div>
                        <table>
                            <thead>
                            <tr key={'table-header'}>
                                <th>Country</th>
                                <th>Total Websites</th>
                            </tr>
                            </thead>
                            <tbody>
                            {countryData.map((item: any, index: number) => (
                                <tr className='tableItem font_standard' key={index}
                                    style={{filter: user?.subscription_activated == false && index >= 5 ? "blur(0.5rem)" : ""}}
                                >
                                    <td style={{width: '60%'}}>
                                        {getCountryDisplayName(item.country)}
                                    </td>
                                    <td style={{width: '40%'}} className='minWidth'>
                                        {item.count}
                                    </td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </>
                )}


            </div>
        </TableDiv>
    )
}

export default Country;