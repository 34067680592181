import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'

function Footer(props:any) {
  const { technology } = props;
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Update the current year every time the component mounts
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <>
      <footer
        className={
          technology === 0 ? "footer-style footer-light" : "footer-light"
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto">
              <div className="widget ">
                <h5>Subscribe to receive occasional product update</h5>

                <div className="d-flex ">
                  <input
                    type="email"
                    className="form-control mr-2"
                    placeholder="info@example.com"
                    name="emailName"
                    id=""
                  />
                  <Link
                    to=""
                    className="btn-custom d-flex"
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#f0ebf9",
                      color: "#4608ad",
                      padding: "5px 15px",
                    }}
                  >
                    <i className="icon_mail p-1"></i>Subscribe
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row" style={{display: 'flex', justifyContent: 'center'}}>
            {/* <div className="col-lg-4">
                            <div className="widget">
                                <Link to="" href="index.html">
                                    <img alt="" className="logo" src="images/logo-1.png" />
                                </Link>
                                <div className="spacer-20"></div>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem.</p>
                            </div>
                        </div> */}

            <div className="col-lg-3">
              <div className="widget">
                <h5>Products</h5>
                <ul>
                  <li>
                    <Link to="/websitelookup">
                      Web Technology Lookup
                    </Link>
                  </li>
                  <li>
                    <Link to="/technologies">
                      Web Technology Usage Lists
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature/detailed-website-profiles">
                      Detailed Website Profiles
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature/competitor-comparison">
                      Competitor Comparison
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature/technology-market-share">
                      Technology Market Share
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature/keyword-based-lists">Keyword Based Lists</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2">
              <div className="widget">
                <h5>Pricing</h5>
                <ul>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="widget">
                <h5>Resources</h5>
                <ul>
                  <li>
                    <Link to="/technologies">Browse Technologies</Link>
                  </li>
                  <li>
                    <Link to="/getting-started">Getting Started</Link>
                  </li>
                  <li>
                    <Link to="/articles">Trends & Insights</Link>
                  </li>
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/help-center">Get Help</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="widget">
                <h5>Support</h5>
                <ul>
                  <li>
                    <Link to="/help-center">Help Center</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Send us your feedback</Link>
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="widget">
                <h5>Company</h5>
                <ul>
                  <li>
                    <Link to="/about-us">About Webrofiler</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms of Service</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>


                </ul>
              </div>
            </div>


            {/* <div className="col-lg-4">
                            <div className="widget">
                                <h5>Newsletter</h5>

                                <p>Signup for our newsletter to get the latest news, updates and special offers in your inbox.</p>
                                <form action="blank.php" className="row" id="form_subscribe" method="post" name="form_subscribe">
                                    <div className="col text-center">
                                        <input className="form-control" id="name_1" name="name_1" placeholder="enter your email" type="text" /> <Link to="" id="btn-submit"><i className="arrow_right"></i></Link>
                                        <div className="clearfix"></div>
                                    </div>
                                </form>
                                <div className="spacer-10"></div>
                                <small>Your email is safe with us. We don't spam.</small>
                            </div>
                        </div> */}
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6 text-sm-left mb-sm-30">
              <div className="mt10">Suite 1005, 3601 Hwy 7 East Markham, Ontario L3R 0M3 Canada &copy; Copyright {currentYear} - Webrofiler
                {/*<Link to="/terms">*/}
                {/*  <span className="ml-3 mr-2">|</span>*/}
                {/*  <span className="ml-2">Terms of Service</span>*/}
                {/*</Link>*/}
                {/*<Link to="/privacy">*/}
                {/*  <span className="ml-3 mr-2">|</span>*/}
                {/*  <span className="ml-2">Privacy Policy</span>*/}
                {/*</Link>*/}
              </div>
            </div>

            <div className="col-md-6 text-md-right text-sm-left">
              <div className="social-icons">
                <a target="_blank" href="https://www.facebook.com/webrofiler">
                  <i className="fa fa-facebook fa-lg"></i>
                </a>
                <a target="_blank" href="https://twitter.com/webrofiler">
                  <i className="fa fa-twitter fa-lg"></i>
                </a>
                <a target="_blank" href="https://linkedin.com/company/webrofiler">
                  <i className="fa fa-linkedin fa-lg"></i>
                </a>
                <a target="_blank" href="https://www.pinterest.ca/webrofiler">
                  <i className="fa fa-pinterest fa-lg"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer
