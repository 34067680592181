import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import {Link, useParams} from 'react-router-dom';
import {Form,Button} from "react-bootstrap";
import {Search} from "react-bootstrap-icons";
import axios from "../../services/commonHttp";
export const routesInit = [
    {name: 'Home', path: '/'},
    {name: 'About Us', path: '/about-us'},
]

interface FormData {
    name: string;
    email: string;
    message: string;
}
const initialFormData: FormData = {
    name: '',
    email: '',
    message: ''
};
const AboutUsContent = () => {
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [formErrors, setFormErrors] = useState<Partial<FormData>>({});
    const [messageSent, setMessageSent] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [sendError, setSendError] = useState<string>('');

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Perform form validation
        const errors: Partial<FormData> = {};

        if (!formData.name) {
            errors.name = 'Name is required';
        }

        if (!formData.message) {
            errors.message = 'Message is required';
        } else if (formData.message.length < 10) {
            errors.message = 'Message should be at least 10 characters long';
        }

        // Perform email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email && !emailRegex.test(formData.email)) {
            errors.email = 'Please enter a valid email address';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        // Submit the form if validation passes
        // ... Your code for submitting the form ...
        // Prepare the form data to send
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('message', formData.message);

        setSending(true)
        axios({
            method: 'post',
            url: `/feedback`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                name: formData.name,
                email: formData.email,
                message: formData.message,
            },
        }).then((response) => {
            // console.log('response', response.data)
            if(response.status === 200) {
                setMessageSent(true);
                setFormData(initialFormData);
                setFormErrors({});
            }
            else {
                setSendError('Something went wrong. Please try again later.' )
            }
        })
            .catch((err) => {
                console.log('err', err)
                setSendError('Something went wrong. Please try again later.' )
            })
            .finally(() => {
                setSending(false)
            })

        // Reset the form data and errors after submission


    };

    return (
        <>
            <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
                <RouteCreator routes={routesInit} />
                <div style={{ textAlign: 'center' }}>
                <h1>About Webrofiler</h1>
                </div>
                {/*<p className='pageDescription'>You can use a quick contact form located on this page to ask a question about our services. We would be happy to answer your questions or offer any help.*/}
                {/*</p>*/}
                <div className="container-fluid ">
                    <div className="row mt-5 mb-5">

                        <div className='col-sm-12'>
                            {/*<h1 style={{ marginTop: "0rem", color: "#35404e" }} >About Webrofiler</h1>*/}
                            <p className="lead">
                                Alive Software Inc., the creators behind successful projects like FlipCreator, proudly presents our latest innovation - Webrofiler. With our extensive expertise in software development, we've engineered a tool designed to streamline the complex process of website technology identification.
                                </p>
                            <p className="lead">
                                Webrofiler offers a comprehensive suite of features including Advanced Search, Competitor Analysis, and a vast Technologies Database. This platform is more than just a web tech stack profiler; it's a tool to navigate the digital landscape, helping businesses to strategize effectively and stay ahead in the ever-evolving digital world.
                               </p>
                            <p className="lead">
                                At Alive Software Inc., we believe in turning challenges into opportunities. Our passion for technology drives us to create tools that empower businesses, and Webrofiler is a testament to our commitment to innovation and excellence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pl-2 pr-2" style={{background: '#ebf7ff'}}>
                <div className="row" >
                    <div className="col text-center mt-5">
                        <div className="switch-set">
                            <h2>What we do</h2>
                        </div>
                    </div>
                </div>
                <div className="container">

                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mt-5 ">
                        <img src={'images/aboutus/Icon-15-100.png'} style={{maxWidth: 50, objectFit: 'contain', height: 'auto', marginTop: 4}}/>
                        <div className="ml-4 mt-2" style={{lineHeight: 1}}>
                            <span style={{fontSize: 15, lineHeight: 0.6, marginTop: 0 }}>At Webrofiler, we're revolutionizing the way businesses and marketers understand the online landscape.</span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mt-5 ">
                        <img src={'images/aboutus/Icon-33-100.png'} style={{maxWidth: 50, objectFit: 'contain', height: 'auto', marginTop: 4}}/>
                        <div className="ml-4 mt-2" style={{lineHeight: 1}}>
                            <span style={{fontSize: 15, lineHeight: 0.6, marginTop: 0}}>Our cutting-edge platform provides in-depth profiling of websites, revealing the technologies they use, from the frontend frameworks to backend services.</span>
                        </div>
                    </div>
                </div>


                <div className="row justify-content-center pb-5">
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mt-5 ">
                        <img src={'images/aboutus/Icon-2-100.png'} style={{maxWidth: 50, objectFit: 'contain', height: 'auto', marginTop: 4}}/>
                        <div className="ml-4 mt-2" style={{lineHeight: 1}}>
                            <span style={{fontSize: 15, lineHeight: 0.6, marginTop: 0}}>With our advanced analytics and custom reporting, you gain actionable insights to inform your digital strategy and outperform competitors.</span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mt-5 ">
                        <img src={'images/aboutus/Icon-9-100.png'} style={{maxWidth: 50, objectFit: 'contain', height: 'auto', marginTop: 4}}/>
                        <div className="ml-4 mt-2" style={{lineHeight: 1}}>
                            <span style={{fontSize: 15, lineHeight: 0.6, marginTop: 0}}>Whether you're an entrepreneur, a marketer, or a researcher, Webrofiler delivers the comprehensive data you need to succeed in today's digital world.</span>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="pl-2 pr-2 pb-5" style={{background: '#ffffff'}}>
                <div className="row" >
                    <div className="col text-center mt-5">
                        <div className="switch-set">
                            <h2>Our mission</h2>
                        </div>
                    </div>
                </div>
                <div className="container">

                <div className="row justify-content-center">
                    <div className="col-lg-12 col-sm-12 row">

                    <div className="col-lg-4 col-md-4 col-sm-12 justify-content-center mt-5 p-2">
                        <img src={'images/aboutus/Icon-27-100.png'} style={{width: "auto", objectFit: 'contain', maxHeight: 45, marginTop: 4}}/>
                        <div className="mt-2" style={{lineHeight: 1}}>
                            <span style={{fontSize: 15, lineHeight: 0.6, marginTop: 0, fontWeight:600 }}>The best</span>
                        </div>

                        <div className="mt-2" style={{lineHeight: 1}}>
                            <span style={{fontSize: 15, lineHeight: 0.6, marginTop: 0 }}>We provide the most comprehensive and accurate website profiling tools, empowering businesses to make informed decisions and gain a competitive edge.</span>
                        </div>
                    </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 justify-content-center mt-5 p-2">
                            <img src={'images/aboutus/Icon-62-100.png'} style={{width: "auto", objectFit: 'contain', maxHeight: 45, marginTop: 4}}/>
                            <div className="mt-2" style={{lineHeight: 1}}>
                                <span style={{fontSize: 15, lineHeight: 0.6, marginTop: 0, fontWeight:600 }}>A growing team</span>
                            </div>

                            <div className="mt-2" style={{lineHeight: 1}}>
                                <span style={{fontSize: 15, lineHeight: 0.6, marginTop: 0 }}>A dynamic team of developers, analysts, and digital strategists, all committed to pushing the boundaries of what's possible in website technology analysis.</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 justify-content-center mt-5 p-2">
                            <img src={'images/aboutus/Icon-11-100.png'} style={{width: "auto", objectFit: 'contain', maxHeight: 45, marginTop: 4}}/>
                            <div className="mt-2" style={{lineHeight: 1}}>
                                <span style={{fontSize: 15, lineHeight: 0.6, marginTop: 0, fontWeight:600 }}>Nonstop support</span>
                            </div>

                            <div className="mt-2" style={{lineHeight: 1}}>
                                <span style={{fontSize: 15, lineHeight: 0.6, marginTop: 0 }}>Customer success is our top priority, which is why we offer round-the-clock support to assist with any questions, concerns, or technical issues.</span>
                            </div>
                        </div>
                </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default AboutUsContent