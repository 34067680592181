import {Tabs, Tab, Button, Modal, Form} from 'react-bootstrap';
import { websiteLookupResultTypes } from '../types/websiteLookupResultTypes';
import {useEffect, useState} from "react";
import EmailRequest from "../inputs/email/EmailRequest";
import emailValidation from "../utils/emailValidation";
import {requestEmailNotification,getRecentLookupsWebsite} from "../utils/api";
import {emailRequest} from "../../../features/websiteData/websiteDataSlice";

const Sidebar = (website: any) => {
    const [totalTechnologies, setTotalTechnologies] = useState(0);
    const [lastDetected, setLastDetected] = useState('')
    const [emailError, setEmailError] = useState<{msg: null | string}>({msg: null})
    const [showCreateNotifiaction, setShowCreateNotification] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [sendingNotification, setSendingNotification] = useState(false);
    const [recentLookups, setRecentLookups] = useState<any>([]);

    const convertDate = (date: string) => {
        const newDate = new Date(date)
        const monthName = newDate.toLocaleString('default', { month: 'short' });
        return `${monthName} ${newDate.getFullYear()}`
    }

    useEffect(() => {
        let count = 0;
        website.websiteData.categories?.map(({technologies}: any) => {
            count += technologies.length;
            let lastDate:any = null

            // find last analyze date
            technologies.map(({analyze_date}: any) => {
                const date = new Date(analyze_date);
                if(lastDate == null || analyze_date > lastDate) {
                    lastDate = date
                }
            })

            if(lastDate) {
                setLastDetected(convertDate(lastDate))
            }
            else {
                setLastDetected('N/A')
            }
        });
        setTotalTechnologies(count);


    }, [website]);

    const handleCreateNotification = async () => {
        if(emailValidation(userEmail)){
            setSendingNotification(true)
            await requestEmailNotification(`/website_analyze/set_notification`, {domain: website.websiteData.website, email: userEmail})
                .then(res => {
                    setSendingNotification(false)
                    if(res.data.success) {
                        setEmailError({msg: null})
                        setShowCreateNotification(false);
                    }
                    else {
                        setEmailError({msg: 'Fail to add your email'})
                    }
                })
                .catch(err => {
                    setSendingNotification(false)
                    setEmailError({msg: err.message})
                })
        }
    }

    const handleClose = () => setShowCreateNotification(false);
    const handleShow = () => setShowCreateNotification(true);


    const fetchRecentLookups = async () => {
        try {
            const response = await getRecentLookupsWebsite();
            if(response.data.success) {
                setRecentLookups(response.data.websites);
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        fetchRecentLookups();
    }, [])

    const handleExportClick = () => {
        window.open(`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/websites/lookup/${website.websiteData.website}`);

    }

    return (
        <>
            <div className="col-md-12" style={{marginTop: "0px"}}>
                <div className="card p-3">
                    <h5 style={{color: 'black'}}>Profile Details</h5>
                    <span>Last technology deteced on {lastDetected} </span>
                    <span>We know {totalTechnologies} technologies on this page </span>
                </div>

                <div className="card p-3 mt-4">
                    <h5 style={{color: 'black'}}>Get notification</h5>
                    <span>Get a notification when <span style={{color: "darkgray"}}>{website.websiteData.website} </span>adds new technologies</span>
                    <Button className="m-4" onClick={handleShow}>
                        Create Notification
                    </Button>
                </div>

                <div className="card p-3 mt-4">
                    <h5 style={{color: 'black'}}>Recent Lookups</h5>
                    <div className="d-flex flex-wrap">
                    {recentLookups.map((item: any, index: number) => (
                            <div className="col-md-6 p-1">
                                <a href={`/websitelookup/${item}`} style={{ textDecoration: 'none'}}>
                                <span style={{fontSize: "14px"}}>{item}</span>
                                </a>
                            </div>
                    ))}

                    </div>

                </div>

                <div className="card p-3 mt-4">
                    <h5 style={{color: 'black'}}>Export CSV</h5>
                    <span>Get <span style={{color: 'darkgray'}}>{website.websiteData.website}</span> profile as an CSV on the dashboard</span>
                    <Button className="m-4" onClick={handleExportClick}>
                        Sign In
                    </Button>
                </div>

                <div className="card p-3 mt-4">
                    <h5 style={{color: 'black'}}>Suggest a Technology</h5>
                    <span>
                        Can't find a technology? Please <a href={'/contact-us'}>send us a suggestion</a>, we will add it to our database.
                    </span>

                </div>
            </div>

            <Modal show={showCreateNotifiaction} onHide={handleClose} centered>
                <Modal.Header >
                    <Modal.Title>Enter Your Email Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formEmail">
                            <Form.Label>
                                To get notified when <span style={{color: "darkgray"}}>{website.websiteData.website} </span>adds new technologies, enter your email address below.
                            </Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={userEmail}
                                onChange={(e)=>setUserEmail(e.target.value)}
                            />
                        </Form.Group>
                        {emailError.msg ? <div className='errorMsg' style={{color: 'red'}}>{emailError.msg}</div> : ''}
                        <div className="d-flex justify-content-center align-items-center col-md-12">
                        <Button variant="primary"
                                className="mt-3"
                                onClick={handleCreateNotification}
                                disabled={sendingNotification}
                        >
                            {sendingNotification ? 'Sending...' : 'Submit'}
                        </Button>
                        <Button variant="secondary" className="mt-3 ml-3" onClick={handleClose}>
                            Cancel
                        </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Sidebar