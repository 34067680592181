import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import type { RootState } from '../../app/store'
import { useSelector, useDispatch } from 'react-redux'
import { loading, website, error, emailRequest } from '../../features/websiteData/websiteDataSlice';
import { getWebsiteResult, requestEmailNotification, getWebsiteAnalyzeStatus } from './utils/api';
import { Main } from './styled-websiteLookup';
import Loader from '../../dashboard-components/common/Loader'
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import { TabInterface } from './tabInterface';
import Upgrade from './upgrade/Upgrade';
import Sidebar from "./sidebar/Sidebar";
import AlertCreator from './alerts/AlertCreator';
import EmailRequest from './inputs/email/EmailRequest';
import emailValidation from './utils/emailValidation';
import {Check2} from "react-bootstrap-icons";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

const WebsiteLookupResult = () => {

  const {websiteName} = useParams();
  const { websiteData } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const [emailError, setEmailError] = useState<{msg: null | string}>({msg: null})
  const [requestId, setRequestId] = useState<string | null>(null)
    const [step1Done, setStep1Done] = useState<boolean>(false)
    const [step2Done, setStep2Done] = useState<boolean>(false)
    const [step3Done, setStep3Done] = useState<boolean>(false)

    let prevWebsiteRef:any = null;
    let checkStatusStarted = false;

  const emailHandler = async (email: string) => {
    setEmailError(prev => ({msg: null}))
    if(emailValidation(email)){
      await requestEmailNotification(`/website_analyze/set_notification`, {domain: websiteName!, email})
        .then(res => {
          if(res.data.success) dispatch(emailRequest({error: res.data.error, name: websiteName, email}))
          else setEmailError({msg: 'Fail to add your email'})
        })
        .catch(error => console.log(error))
    }
    else setEmailError({msg: 'Invalid Email please Enter the correct one!'})
  }

  const loadWebsiteData = async () => {
      await getWebsiteResult(`/website_analyze?url=${websiteName}`)
          .then(res => {
              if(res.data.success === false) dispatch(error({error: res.data.error, name: websiteName}))
              else {
                  if(res.data.request_id) {
                      setRequestId(res.data.request_id)
                      setStep1Done(true)
                  }
                  else {
                      setStep1Done(true)
                      setStep2Done(true)
                      setStep3Done(true)
                      console.log('website', res.data.website)
                     dispatch(website({websiteDetails: res.data.website, name: websiteName}))
                  }
              }
          })
          .catch(error => {
              console.log(error)
                dispatch(error({error: "FailToAnalyze", name: websiteName}))
          })
  }

  useEffect(()=>{
      if(prevWebsiteRef == websiteName) return;
      prevWebsiteRef = websiteName;
      async function getData(){

        console.log('websiteName', websiteName)
      if(websiteData.name !== websiteName){
        if(websiteData.loading === false) dispatch(loading(true))
        loadWebsiteData()
      }
    }
    if(websiteName) getData();
  }, [websiteName])

  const checkStatus = async () => {
      let isDone = false;
    await getWebsiteAnalyzeStatus(`/website_anaylze_status?request_id=${requestId}`)
        .then(res => {
          if(res.data.success === false) dispatch(error({error: res.data.error, name: websiteName}))
          else {
            if(res.data.status === 'pending') {
                setStep1Done(true)
            }
            if(res.data.status === 'processing') {
                setStep1Done(true)
                setStep2Done(true)
            }
            if(res.data.status === 'completed') {
                setStep1Done(true)
                setStep2Done(true)
                setStep3Done(true)
                loadWebsiteData()
                isDone = true;
                return;
            }
            if(res.data.status === 'failed') {
                dispatch(error({error: "FailToAnalyze", name: websiteName}))
                isDone = true;
                return;
            }
            console.log(res.data.status)
          }
        })
        .catch(error => {
            console.log(error)
            isDone = true;
            dispatch(error({error: "FailToAnalyze", name: websiteName}))
        })

      if(!isDone) {
          setTimeout(async ()=>{
              checkStatus()
          }, 8000)
      }
  }

  useEffect(()=>{
      if(!checkStatusStarted) {
          checkStatusStarted = true;
      }
      else {
          return;
      }
      if(requestId){
          setTimeout(async ()=>{
              checkStatus()
          }, 3000)
      }
  },[requestId])

  return ( 
    <Main>
      {
        websiteData.loading
        ?
            <>
        <div className='loading' style={{minWidth: 400}}>
            <div className="d-flex align-items-center justify-content-start">
                <span className='title'>Website Analyzing...</span>
            </div>
            <div className="d-flex mt-3 ">
                <div className="spinner ml-1 mt-3 mb-3">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>

            <div className="demo-icon-wrap d-flex align-items-center justify-content-start mt-3" style={{marginBottom: 15}}>
                <i className="fa fa-fw" aria-hidden="true" title="Copy to use check" style={{color: step1Done ? "blue" : "lightgrey"}}></i>
                <span style={{color: step1Done ? "black" : "lightgrey"}}>Prepare for analyzing</span>
            </div>

            <div className="demo-icon-wrap d-flex align-items-center justify-content-start" style={{marginBottom: 15}}>
                <i className="fa fa-fw" aria-hidden="true" title="Copy to use check" style={{color: step2Done ? "blue" : "lightgrey"}}></i>
                <span style={{color: step2Done ? "black" : "lightgrey"}}>   Start analyzing...</span>
            </div>

            <div className="demo-icon-wrap d-flex align-items-center justify-content-start" style={{marginBottom: 15}}>
                <i className="fa fa-fw" aria-hidden="true" title="Copy to use check" style={{color: step3Done ? "blue" : "lightgrey"}}></i>
                <span style={{color: step3Done ? "black" : "lightgrey"}}>Done</span>
            </div>

        </div>


          </>
        :
         websiteData.website
        ?
        <div className='container websiteDetails' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
          <RouteCreator routes={websiteData.routes} />
          <h1>{websiteName}</h1>
          {typeof websiteData.website === 'object' &&
              ( websiteData.website.status !== 'done' ?
                <AlertCreator message={'We are starting to analyze the website, please wait a few minutes'} variant='primary' />
              :
                      <div className="row col-md-12 justify-content-start align-items-start">
                          <div className="row col-md-12 col-lg-7">
              <TabInterface
            categories={websiteData.website.categories}
            meta_title={websiteData.website.meta_title}
            description={websiteData.website.description}
            keywords={websiteData.website.keywords}
            company_name={websiteData.website.company_name}
            emails={websiteData.website.emails}
            phones={websiteData.website.phones}
            facebook={websiteData.website.facebook}
            instagram={websiteData.website.instagram}
            linkedin={websiteData.website.linkedin}
            pinterest={websiteData.website.pinterest}
            tiktok={websiteData.website.tiktok}
            twitter={websiteData.website.twitter}
            youtube={websiteData.website.youtube}
          />
                          </div>
                          <div className="row col-sm-12 col-lg-5 mt-0 mb-4">
                              <Sidebar websiteData={websiteData.website}/>
                          </div>
                          <div className="row col-md-12">
                          <div className="col-md-12 d-flex">
                              <Upgrade />
                          </div>
                          </div>
                      </div>
              )}

        </div>
        :
        websiteData.error
        ?
        <div className='container websiteDetails' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
          <RouteCreator routes={websiteData.routes} />
          <h1>{websiteName}</h1>
          {
            websiteData.error === 'InvalidWebsite' ? <AlertCreator message={'The website is unable to access'} variant='danger' /> :
            websiteData.error === 'AddToQueue' ? <AlertCreator message={'We are starting to analyze the website, and we\'ll email you when it\'s ready.'} variant='primary' /> :
            websiteData.error === 'Invalid URL' ? <AlertCreator message={'Invalid url.'} variant='primary' /> :
                websiteData.error === 'FailToAnalyze' ?
                    <>
                        <AlertCreator message={'Oops! We\'ve encountered an error while analyzing this website. Please leave your email and we\'ll update you as soon as it\'s resolved.'} variant='primary' />
                        <EmailRequest email={websiteData.email} error={emailError} emailHandler={emailHandler} />
                    </>
                    :
            <>
              <AlertCreator message={'We are starting to analyze the website, and we\'ll email you when it\'s ready.'} variant='primary' />
              <EmailRequest email={websiteData.email} error={emailError} emailHandler={emailHandler} />
            </>
          }
        </div>
        : ''
      }
    </Main>
  )
}

export default WebsiteLookupResult