import { useState, useEffect } from 'react';
import { Main } from './styled-productfeature';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Button} from "react-bootstrap";

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'Detailed Website Profiles', path: '/feature/detailed-website-profiles'},
]

const DetailedWebsiteProfilesFeaturePage = () => {

  useEffect(() => {

},[])

  return ( 
    <Main>
        <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
            <RouteCreator routes={routesInit} />
            <div className='content-container'>
                <h1 className="text-center">Detailed Website Profiles</h1>
                <div className="descDiv">
                  <p className='pageDescription'> It offers detailed information about a website's technology stack, metadata, and contact details. Users can access valuable insights into the technologies used, metadata information, and easily find relevant contact information, making it a valuable tool for research, analysis, and networking purposes.</p>
                </div>
                <div>
                    <img className="screenshot" src="/images/features/website-detailed.png" alt="screenshot1"></img>
                </div>
                <div className="signupDiv">
                  <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`}>
                      <Button variant='primary' size='lg'>Sign Up for Free</Button>
                  </a>
                </div>
            </div>
        </div>
    </Main>
  )
}

export default DetailedWebsiteProfilesFeaturePage