import styled from "styled-components";

export const SpacerSingle = styled.div`
  margin-bottom: 3rem;

  @media (max-width: 879px) {
    margin-bottom: 8rem;
  }
`;

export const SecondSection = styled.section`
  margin-top: 3rem;
  padding-top: 0px;

  @media (max-width: 879px) {
    margin-top: 8rem;
  }
`;
