import { Footer } from '../../components/footer';
import TermsContent from "../../components/TermsContent";

const TermsView = () => {
    return (
        <div id='wrapper'>
            <TermsContent />
            <Footer />
        </div>
    )
}

export default TermsView;