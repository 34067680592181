import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import {Link, useParams} from 'react-router-dom';
import {Form,Button} from "react-bootstrap";
import {Search} from "react-bootstrap-icons";
import axios from "../../services/commonHttp";
export const routesInit = [
    {name: 'Home', path: '/'},
    {name: 'Terms', path: '/terms'},
]

const TermsContent = () => {

    return (
        <>
            <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
                <RouteCreator routes={routesInit} />
                <h1>Webrofiler Terms and Conditions</h1>
                {/*<p className='pageDescription'>You can use a quick contact form located on this page to ask a question about our services. We would be happy to answer your questions or offer any help.*/}
                {/*</p>*/}
                <div className="container-fluid ">
                    <div className="row offset-md-1 mt-5 mb-5">
                        <h2>1. Introduction</h2>
                        <p>
                            By accessing and using the services provided by Webrofiler, a service of Alive Software Inc., you agree to abide by the following terms and conditions. These terms apply to all visitors, users, and others who access the service.
                        </p>
                        <h2>2. Acceptance of Terms</h2>
                        <p>
                            Please read these Terms and Conditions carefully before using the Service. By accessing or using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree to these terms, you may not access or use the Service.
                        </p>
                        <h2>3. Use of the Service</h2>
                        <p>
                            a. Eligibility: You must be at least 18 years old and have the legal authority to enter into these Terms and Conditions. By accessing or using the Service, you represent and warrant that you meet these requirements.
                        </p>
                        <p>
                            b. Account: To access certain features of the Service, you may need to create an account. You are responsible for maintaining the confidentiality of your account credentials and for any activities conducted through your account.
                        </p>
                        <h2>4. Privacy and Data Protection</h2>
                        <p>
                            Your privacy is important to us. Please refer to our Privacy Policy to understand how we collect, use, and protect your personal data.
                        </p>
                        <h2>5. Intellectual Property</h2>
                        <p>
                            a. Ownership: The Service and all associated content, including but not limited to logos, trademarks, text, graphics, images, and software, are the property of Alive Software Inc. or its licensors and are protected by applicable intellectual property laws.
                        </p>
                        <p>
                            b. Restrictions: You may not copy, modify, distribute, sell, or lease any part of the Service or its content without our prior written consent.
                        </p>
                        <h2>6. Service Provision</h2>
                        <p>
                            Webrofiler services are provided on an "as is" basis. We make no warranty or representation about the accuracy, reliability, completeness, or timeliness of the content provided.
                        </p>
                        <h2>7. User Obligations</h2>
                        <p>
                            You agree not to use the service to violate any laws or regulations or to infringe the rights of any third party.
                        </p>
                        <h2>8. Limitation of Liability</h2>
                        <p>
                            In no event shall Alive Software Inc., its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, resulting from your use of the service.
                        </p>
                        <h2>9. Governing Law</h2>
                        <p>
                            These Terms shall be governed and construed in accordance with the laws of Canadian, without regard to its conflict of law provisions.
                        </p>
                        <h2>10. Payment for Services</h2>
                        <p>
                            Users of Webrofiler are required to pay the fees for services as specified on our website at the time of purchase. All fees are in U.S. dollars, unless otherwise specified.
                        </p>
                        <p>
                            Webrofiler reserves the right to change the prices for services at any time, at its sole discretion. We will provide advance notice of any fee changes to our users.
                        </p>
                        <p>
                            All payments must be made by the payment methods we support at the time of purchase. By making a payment, you agree to the terms and conditions of the applicable payment processor and your financial institution.
                        </p>
                        <h2>11. Refund Policy</h2>
                        <p>
                            All sales are final, and Webrofiler does not offer refunds. However, if you believe that you have been incorrectly billed, please contact us immediately so that we can resolve the issue.
                        </p>
                        <p>
                            We reserve the right to issue refunds at our sole discretion. If we issue a refund, the refund will be credited back to the original payment method.
                        </p>
                        <p>
                            Please note that by purchasing a service from Webrofiler, you agree to this refund policy.
                        </p>
                        <h2>12. Termination and Suspension</h2>
                        <p>
                            If a user breaches any of these terms, or if Webrofiler is unable to verify or authenticate any information the user provides, Webrofiler may, at its sole discretion, terminate or suspend the user's account and refuse any and all current or future use of the service.
                        </p>
                        <h2>13. Changes to these Terms</h2>
                        <p>
                            We reserve the right to revise these terms at any time. Any changes will be effective immediately upon posting on our website. By continuing to use the service after changes are made, you agree to be bound by the revised terms.
                        </p>
                        <h2>14. Contact Us</h2>
                        <p>
                            If you have any questions about these Terms, please contact us at support@webrofiler.com.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsContent