import axios from '../../../services/commonHttp';

export const getWebsiteResult = async (url:string) => axios({
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    url, 
})

export const requestEmailNotification = async (url:string, params:{domain: string, email:string}) => axios({
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    url, 
    params
})

export const getWebsiteAnalyzeStatus = async (url:string) => axios({
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    url,
})

export const getWebsiteSuggestions = async (keyword:string, cancelToken:any) => axios({
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    url: '/website_suggestions?keyword=' + keyword,
    cancelToken: cancelToken
})

export const getRecentLookupsWebsite = async () => axios({
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    url: '/recent_lookup',
})

export const getAllGroups = async () => axios({
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    url: '/all_groups',
})

export const getRelatedTechnolgies = async (techId:string) => axios({
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    url: '/related_technologies/' + techId,
})

