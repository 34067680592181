import {TechStackDiv} from './styled-tabs'

const TechProfile = ({categories}:any) => {
    const now = new Date();
    const date = now.toLocaleDateString('en-US');

    const convertDate = (date: string) => {
        const newDate = new Date(date)
        const monthName = newDate.toLocaleString('default', { month: 'short' });
        return `${monthName} ${newDate.getFullYear()}`
    }

    return (
        <TechStackDiv >
            {categories?.length
                ? (
                <>
                <table className="table table-borderless"
                >
                    <thead>
                    <tr>
                        <th scope="col">Technology</th>
                        <th scope="col">First Detected</th>
                        <th scope="col">Last Detected</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories.map(({category: {name, id}, technologies}: any, index: number) =>
                        <>
                            <tr style={{background: "#F4F2F2"}}>
                                <td>{name}</td>
                                <td></td>
                                <td></td>
                            </tr>

                            {technologies.map(({id, name, icon, version,description, analyze_date}: any, index: number) => (
                                <>
                                    <tr style={{background: "white", paddingTop: "120px"}} className="mb-3" >
                                        <td style={{marginTop: "100px"}} >
                                            <img src={`${process.env.PUBLIC_URL}/images/icons/${icon}`} style={{ 'height': '32px', 'width': '32px', 'margin': '0px' }} />
                                            <span className="ml-2">{name}</span>
                                        </td>
                                        <td >{convertDate(analyze_date)}</td>
                                        <td >{convertDate(date)}</td>
                                    </tr>
                                </>
                            ))}
                        </>
                    )}
                    </tbody>

                </table>

                    </>
                )
                :
                <div className='notFound'>Technology stack is not found for this website</div>
            }
        </TechStackDiv>
    )
}

export default TechProfile