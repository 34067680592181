import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import {Link, useParams} from 'react-router-dom';
import {Form,Button} from "react-bootstrap";
import {Search} from "react-bootstrap-icons";
import axios from "../../services/commonHttp";
export const routesInit = [
    {name: 'Home', path: '/'},
    {name: 'Contact Us', path: '/contact-us'},
]

interface FormData {
    name: string;
    email: string;
    message: string;
}
const initialFormData: FormData = {
    name: '',
    email: '',
    message: ''
};
const ContactUsContent = () => {
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [formErrors, setFormErrors] = useState<Partial<FormData>>({});
    const [messageSent, setMessageSent] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [sendError, setSendError] = useState<string>('');

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Perform form validation
        const errors: Partial<FormData> = {};

        if (!formData.name) {
            errors.name = 'Name is required';
        }

        if (!formData.message) {
            errors.message = 'Message is required';
        } else if (formData.message.length < 10) {
            errors.message = 'Message should be at least 10 characters long';
        }

        // Perform email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email && !emailRegex.test(formData.email)) {
            errors.email = 'Please enter a valid email address';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        // Submit the form if validation passes
        // ... Your code for submitting the form ...
        // Prepare the form data to send
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('message', formData.message);

        setSending(true)
        axios({
            method: 'post',
            url: `/feedback`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                name: formData.name,
                email: formData.email,
                message: formData.message,
            },
        }).then((response) => {
            // console.log('response', response.data)
            if(response.status === 200) {
                setMessageSent(true);
                setFormData(initialFormData);
                setFormErrors({});
            }
            else {
                setSendError('Something went wrong. Please try again later.' )
            }
        })
            .catch((err) => {
                console.log('err', err)
                setSendError('Something went wrong. Please try again later.' )
            })
            .finally(() => {
                setSending(false)
            })

        // Reset the form data and errors after submission


    };

    return (
        <>
            <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
                <RouteCreator routes={routesInit} />

                <div className="container-fluid">
                    <div className="row mt-5 mb-5">
                        <div style={{ textAlign: 'center' }} className='mb-5'>
                            <h1>Contact Us</h1>
                        </div>

                        <p className='pageDescription'>You can use a quick contact form located on this page to ask a question about our services. We would be happy to answer your questions or offer any help.
                        </p>
                        <div className="mt-4"/>
                        <div className='col-sm-6 mr-4 mb-4'>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        isInvalid={!!formErrors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        isInvalid={!!formErrors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="message">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        isInvalid={!!formErrors.message}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors.message}</Form.Control.Feedback>
                                </Form.Group>
                                <Button type="submit" variant="primary" className="mt-5"
                                disabled={sending}>
                                    {sending ? 'Sending...' : 'Send'}
                                </Button>

                                {messageSent && (
                                    <p className="mt-3 text-success">Your message has been sent successfully!</p>
                                    )}
                                {sendError && (
                                    <p className="mt-3 text-danger">{sendError}</p>
                                )}
                            </Form>
                        </div>
                        <div className='col-5'>
                            <div className="row align-items-center">
                                <div className="col-auto d-flex align-items-center">
                                    <i className="fa fa-map-marker fa-lg"></i>
                                </div>
                                <div className="col">
                                    <p className="mb-0">Webrofiler</p>
                                </div>

                                <p className="mb-0 mt-2">Suite 1005, 3601 Hwy 7 East Markham, Ontario L3R 0M3 Canada</p>

                            </div>

                        </div>

                        <div style={{ textAlign: 'center' }} className='mb-5 mt-5'>
                            <h1>Office Location</h1>
                        </div>
                        <div className='col-12'>
                            <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item"
                                    src="https://maps.google.ca/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=Suite+1005,+3601+Hwy+7,+Markham,+ON&amp;aq=&amp;sll=43.858359,-79.337769&amp;sspn=0.041775,0.104628&amp;ie=UTF8&amp;hq=&amp;hnear=3601+Hwy+7+%231004,+Markham,+Ontario+L3R+0G6&amp;t=m&amp;ll=43.862196,-79.337168&amp;spn=0.02166,0.053644&amp;z=14&amp;iwloc=A&amp;output=embed"
                                    ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUsContent