import { useState, useEffect } from 'react';
import { Main } from './styled-productfeature';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Button} from "react-bootstrap";

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'Technology Market Share', path: '/feature/technology-market-share'},
]

const TechnologyMarketShareFeaturePage = () => {

  useEffect(() => {

},[])

  return ( 
    <Main>
        <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
            <RouteCreator routes={routesInit} />
            <div className='content-container'>
                <h1 className="text-center">Technology Market Share</h1>
                <div className="descDiv">
                  <p className='pageDescription'>Technology Market Share is a comprehensive analysis that reveals the distribution of technologies utilized by websites. Each list of websites provided offers valuable insights into the specific technologies being used, highlighting which ones are most prevalent in the market. This information helps users understand the technology landscape, identify popular trends, and make informed decisions regarding their own technology choices.




                  </p>
                </div>
                <div>
                    <img className="screenshot" src="/images/features/technolgoy-market-share.png" alt="screenshot1"></img>
                </div>
                <div className="signupDiv">
                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`}>
                        <Button variant='primary' size='lg'>Sign Up for Free</Button>
                    </a>
                </div>
            </div>
        </div>
    </Main>
  )
}

export default TechnologyMarketShareFeaturePage