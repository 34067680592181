import styled from "styled-components";

export const Main = styled.main`
    min-height: 800px;
    position: relative;
    background-color: #ffffff;

    & a{
        @media (max-width: 500px){
            font-size: 15px !important;
        }
    }

    
    & p.pageDescription{
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 110px;
    }

    & div.accordion{
        box-sizing: border-box;
        width: 100%;
        max-width: 877px;
        margin: 0 auto 20px;

        & div.accordion-item{
            margin-top: 20px;

            & h2.accordion-header{
                width: 100%;
                height: 100%;
                margin-bottom: -6px;
                
                & button{
                    background-color: #e9ecef;
                    font-weight: 500;
                    font-size: 24px;
                    height: 100%;
                    color: black;

                    &:not(.collapsed):after{
                        transform: rotate(0deg);
                    }

                    &:after{
                        transform: rotate(90deg);
                    }
                }
            }
        }

        & div.inputDiv{
            width: 100%;
            position: relative;

            & input{
                margin: 24px auto;
            }

            & svg{
                position: absolute;
                right: 0;
                top: 0;
                margin: 10px 15px 0 0;
            }
        }
    }

    & p.uploadFileTitle{
        font-size: 22px;
    }

    .switch-set div{
        display:inline-block;
        padding:0 5px;
    }
    
    .switch {
        margin-bottom:-5px;
         -webkit-appearance: none;
         height: 24px;
         width: 44px;
         background-color: #EEEEEE;
         border-radius: 60px;
         position: relative;
         cursor: pointer;
    }
     .switch::after {
         content: "";
         width: 20px;
         height: 20px;
         background-color: #017DFC;
         position: absolute;
         border-radius: 100%;
         transition: .5s;
         margin:2px 0 0 2px;
    }
    /*
     .switch:checked {
         background-color: blue;
    }
    */
     .switch:checked::after {
         transform: translateX(20px);
    }
     .switch:focus {
         outline-color: transparent;
    }
 
    .content-container {
        padding: 0px 10%;
    }

    .v-application {
        font-family: Averta,Arial,sans-serif;
        line-height: 1.5;
    }

    .v-application .text-decoration-none {
        text-decoration: none!important;
    }

    v-application .text--primary {
        color: #000!important;
    }

    .v-application .text-h2, .v-application .text-h3 {
        line-height: 1.5em;
        font-family: Averta,Arial,sans-serif!important;
    }

    .v-application .mb-2 {
        margin-bottom: 8px!important;
    }

    .v-application .mt-8 {
        margin-top: 32px!important;
    }

    .v-application .text-decoration-underline {
        text-decoration: underline!important;
    }

    .v-application p {
        margin-bottom: 16px;
        font-size: 18px;
    }

    .v-application a {
        color: #000 !important;
    }

    .v-application .article-content a {
        color: #2196f3 !important;
        cursor: pointer;
        text-decoration: underline;
    }
    
    .v-application .article-content a:hover {
        color: #1146f3 !important;
        cursor: pointer;
        text-decoration: underline;
    }

    .section-title {
        line-height: 1.5em;
        font-size: 1.75rem!important;
        font-weight: 400;
        letter-spacing: -.0083333333em!important;
        margin-bottom: 8px!important;
        margin-top: 32px!important;
    }

    .section-image {
        margin-top: 32px!important;
        margin-bottom: 32px!important;
        position: relative;
        overflow: hidden;
        flex: 1 0 auto;
        max-width: 100%;
        display: flex;
        width: 100%;
        height: 100%;
    }

    .article-content {
        margin-top: 32px!important;
    }

`