import { useState, useEffect } from 'react';
import { Main } from './styled-FAQs';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import Upgrade from '../websiteLookupResult/upgrade/Upgrade';
import Accordion from 'react-bootstrap/Accordion';
import {Form} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'FAQs', path: '/faqs'},
]

const FAQs = () => {
  const [faqData, setFAQData] = useState<string>();
  const [activeGroup, setActiveGroup] = useState<any>();

  const fetchJson = () => {
    fetch(process.env.REACT_APP_FAQS_URL as string)
    .then(response => {
      return response.json();
    }).then(data => {
      setFAQData(data);
      setActiveGroup("1");
    }).catch((e: Error) => {
      console.log(e.message);
    });
  }

  const FAQGroup = (props: any) => {
    return (
        <div>
        {
            props.data && props.data.map ((group: any) =>(
                <div key={group.id} className={activeGroup === group.id ? 'mx-2 v-list-item--active v-list-item primary--text' : 'mx-2 v-list-item'} tabIndex={0} role="listitem" onClick={() => showContent(group.id)}>
                    <div className="v-list-item__icon">
                    </div>
                    <div className="v-list-item__content">
                        <div className="v-list-item__title">
                            { group.title }
                        </div>
                    </div>
                </div>
            ))
          }
        </div>
    );
  }  

  const FAQGroupItems = (props: any) => {
    return (
      <div>
        {
          props.data && props.data.map ((group: any) =>(
            <div key={group.id} style={{ display: activeGroup === group.id ? 'block' : 'none' }}>
              <Accordion>
                {
                  group.items && group.items.map ((item: any) =>(
                    <Accordion.Item key={item.id} eventKey={item.id} style={{marginTop: 0, marginBottom: 10}}>
                      <Accordion.Header>{item.title}</Accordion.Header>
                      <Accordion.Body>{item.content}</Accordion.Body>
                  </Accordion.Item>
                  ))
                }
              </Accordion>
            </div>
          ))
        }
    </div>
    );
  }    
  useEffect(() => {
    fetchJson()
  },[])

  const showContent = (id: any) => {
    setActiveGroup(id);
  }

  return ( 
    <Main>
        <div className="v-application secondary">
          <div className='container websiteDetails' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
              <RouteCreator routes={routesInit} />
              <div className='row mb-5' style={{textAlign: 'center'}}>
              <h1>Frequently asked questions</h1>
              </div>
              <div className='row'>
                <div className="col-sm-4 col-lg-3 order-sm-0 col-12 order-2">
                  <aside className="v-navigation-drawer v-navigation-drawer--floating v-navigation-drawer--open secondary" style={{height: 'auto', top: '0px', transform: 'translateX(0%)', width: '100%'}} data-booted="true">
                    <div className="v-navigation-drawer__content">
                      <div role="list" className="v-list px-0 v-sheet v-list--dense v-list--nav">
                        <FAQGroup data={faqData} />
                      </div>
                    </div>
                    <div className="v-navigation-drawer__border">
                    </div>
                  </aside>
                </div>

                <div className="col-sm-8 col-lg-9 col-12">
                  <div style={{ textAlign: 'center' }}>
                  </div>
                    <FAQGroupItems data={faqData} />
                </div>              
              </div>

          </div>
        </div>
    </Main>
  )
}

export default FAQs