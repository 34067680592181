import React, {useEffect, useState} from 'react';
import { TableDiv, Progress } from './styled-table';
import PlaceholderLoading from 'react-placeholder-loading';
import findPercentageMax from '../utils/findPercentageMax';
import SharedDataContext from "../../../contexts/SharedDataContext";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {getRelatedTechnolgies} from "../../websiteLookupResult/utils/api";
import {Link} from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);
const Competitors = (technologyData:any) => {
    const allGroups = React.useContext(SharedDataContext).groups;
    const [competitorsData, setCompetitorsData] = useState<any[]>([]); // Define the correct type for your countries data

    const fetchRelatedTechnologies = async () => {
        try {
            // console.log('get related technologies', technologyData.id)
            const response = await getRelatedTechnolgies(technologyData.id);
            if(response.data.success == true) {
                // console.log('related technologies', response.data.technologies.length)
                setCompetitorsData(response.data.technologies);
            }
        }
        catch (error) {
            console.log('failed to fetch related technologies', error);
        }
    }
    useEffect(() => {
        const belongsCategories = technologyData.categories
        fetchRelatedTechnologies()
    }, [technologyData])

    return (
        <TableDiv>
            <div className='tableDiv'>

                {technologyData == null ? (
                    <></>
                ) : (
                    <>
                        <div className="mb-3">
                            <h5>{technologyData?.name} Competitors and Similar</h5>
                        </div>

                        <div className="row col-md-12 col-lg-12 flex-wrap">
                            {competitorsData?.map((tech: any) => (
                                <>
                                        <a href={`/technology/compact/${tech.id}`} className="font_standard col-sm-1 col-md-6 col-lg-6 row d-flex justify-content-start align-items-center">
                                            <img src={`/images/icons/${tech.icon}`} alt="Icon" style={{ height: '50px', width: '50px', objectFit: 'contain' }} />
                                            {tech.name}
                                        </a>

                                </>
                            ))}
                        </div>

                    </>
                )}


            </div>
        </TableDiv>
    )
}

export default Competitors;