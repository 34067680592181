import React from 'react';
import { TableDiv, Progress } from './styled-table';
import PlaceholderLoading from 'react-placeholder-loading';
import findPercentageMax from '../utils/findPercentageMax';
import authContext from "../../../contexts/AuthContext";

type tableProps = {
    websites: {website: string; traffic: number}[];
    title: string;
}

const Table = ({ websites, title }: tableProps) => {
    const percent = findPercentageMax(websites);
    const {user} = React.useContext(authContext);

    console.log('user', user)
    return (
        <TableDiv>
            {title ? <h5>Top Websites using {title}</h5> : <PlaceholderLoading shape="rect" width={523} height={41} />}
            <div className='tableDiv'>
                <table>
                    <thead>
                        <tr key={'table-header'}>
                            <th>#</th>
                            <th>Website</th>
                        </tr>
                    </thead>
                    <tbody>
                        {websites.length ? websites.map(({ website, traffic }, index) => (
                            <tr className='tableItem' key={index}>
                                <td style={{width: '4%'}}>{index+1}</td>
                                <td style={{width: '48%'}} className='minWidth'>
                                    <a href={user?.subscription_activated == false && index >= 5 ? "" : `http://${website}`} className='blueColor font_standard' rel="noopener noreferrer" target='_blank'
                                       style={{filter: user?.subscription_activated == false && index >= 5 ? "blur(0.5rem)" : ""}}
                                    >
                                        {website}</a>
                                </td>
                            </tr>
                        )) : (
                            [...Array(10)].map((item, index) => (
                                <tr className='tableItem' key={index}>
                                    <td style={{width: '4%'}}>{index+1}</td>
                                    <td style={{width: '48%'}} className='minWidth'>
                                        <PlaceholderLoading shape="rect" width={300} height={20} />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </TableDiv> 
    )
}

export default Table;