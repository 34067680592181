import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import {Link, useParams} from 'react-router-dom';
import {Form,Button} from "react-bootstrap";
import {Search} from "react-bootstrap-icons";
import axios from "../../services/commonHttp";
import parse from 'html-react-parser';
import title from "../technologyProfileCompact/title/Title";



interface FormData {
    name: string;
    email: string;
    message: string;
}
const initialFormData: FormData = {
    name: '',
    email: '',
    message: ''
};

interface GettingStartContentProps {
    link: string;
    title: string;
}

const GettingStartContent = (props:GettingStartContentProps) => {
    const [content, setContent] = useState<string>('');
    const [routeInit, setRouteInit] = useState<any>([]);
    const link = props.link;
    const title = props.title;

    const fetchContent = async () => {
        try {
            const response = await fetch(link);
            if(!response.ok) {
                throw new Error('Something went wrong');
            }
            else {
                const data = await response.text();
                setContent(data);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchContent();

    }, [link]);

    useEffect(() => {
        setRouteInit([
            {name: 'Home', path: '/'},
            {name: title, path: '#'},
        ])
    },[title]);

    return (
        <>
            <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
                <RouteCreator routes={routeInit} />
                <div>
                    {parse(content)}
                </div>
                {/*<div dangerouslySetInnerHTML={{ __html: content }} />*/}
            </div>
        </>
    )
}

export default GettingStartContent