import React, {useEffect} from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import {
  BrowserRouter,
  createBrowserRouter,
  RouterProvider,
  Routes, Route, Router
} from "react-router-dom";

import { Home } from "./views/main-views/home";
import { Technology } from "./views/main-views/technology";
import { Dashboard } from "./dashboard-views";
import { Main } from './views/layouts/mainLayout';
import { DashboardLayout } from './views/layouts/dashboardLayout';
import Error from './components/Error'
import TechnologySearch from './dashboard-views/TechnologySearch';
import TechnologyCatalogs from './dashboard-views/TechnologyCatalogs';
import { ChartView } from "./views/categoryOverview";
import { TechnologyProfileCompactView } from './views/technologyProfileCompact';
import { WebsiteLookupResultView } from './views/websiteLookupResult';
import { WebsiteLookupView } from './views/websiteLookup';
import { PricingView } from './views/pricing';
import { FAQsView } from './views/FAQs';
import { ArticlesView } from './views/Articles';
import { ArticleContentView } from './views/ArticleContent';
import { DetailedWebsiteProfilesFeaturePageView } from './views/DetailedWebsiteProfilesFeaturePage';
import { CompetitorComparisonFeaturePageView } from './views/CompetitorComparisonFeaturePage';
import { TechnologyMarketShareFeaturePageView } from './views/TechnologyMarketShareFeaturePage';
import { KeywordBasedListsFeaturePageView } from './views/KeywordBasedListsFeaturePage';
import { ContactUsContentView } from "./views/ContactUs";
import { AboutUsContentView} from "./views/AboutUs";
import TermsView from "./views/Terms";
import PrivacyView from "./views/Privacy";
import { useLocation } from "react-router-dom";
import { GettingStartedView } from "./views/GettingStartedView";

function App() {

    const location = useLocation();
  useEffect(() => {
    console.log('location', location)
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  },[location]);

  // const router = createBrowserRouter([
  //   {
  //     path: "/",
  //     element: <Home/>
  //   },
  //   {
  //     path: "/technologies",
  //     element: <Technology/>
  //   },
  //   {
  //     path: "/dashboard",
  //     element: <Dashboard/>
  //   },
  // ]);
  return (
    <>
      <Routes>
        <Route path='/' element={<Main />}>
          <Route index element={<Home />} />
          <Route path='/technologies' element={<Technology />} />
          <Route path='/technology/compact/:techId' element={<TechnologyProfileCompactView />} />
          <Route path='/websitelookup/:websiteName' element={<WebsiteLookupResultView />} />
          <Route path='/websitelookup' element={<WebsiteLookupView />} />
          <Route path='/pricing' element={<PricingView />} />
          <Route path='/faqs' element={<FAQsView />} />
          <Route path='/articles' element={<ArticlesView />} />
          <Route path='/articles/:slug' element={<ArticleContentView />} />
          <Route path='/feature/detailed-website-profiles' element={<DetailedWebsiteProfilesFeaturePageView />} />
          <Route path='/feature/competitor-comparison' element={<CompetitorComparisonFeaturePageView />} />
          <Route path='/feature/technology-market-share' element={<TechnologyMarketShareFeaturePageView />} />
          <Route path='/feature/keyword-based-lists' element={<KeywordBasedListsFeaturePageView />} />
          <Route path='/contact-us' element={<ContactUsContentView />} />
          <Route path='/about-us' element={<AboutUsContentView />} />
          <Route path='/terms' element={<TermsView />} />
          <Route path='/privacy' element={<PrivacyView />} />
          <Route path='/getting-started' element={<GettingStartedView title="Getting Started" link={"/pages/gettingStarted.txt"} />} />
          <Route path='/help-center' element={<GettingStartedView title="Help Center" link={"/pages/helpcenter.txt"} />} />
          <Route path='/what-is-webrofiler' element={<GettingStartedView title="What is Webrofiler" link={"/pages/whatiswebrofiler.txt"} />} />
          <Route path='/one-of-the-best-solution' element={<GettingStartedView title="One of The Best Solution" link={"/pages/oneofthebestsolution.txt"} />} />
          <Route path='/how-webrofiler-continues-to-innovate' element={<GettingStartedView title="How Webrofiler Continues to Innovate" link={"/pages/HowWebrofilerContinuestoInnovate.txt"} />} />
        </Route>
        
        <Route path='/dashboard' element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
          <Route path='technology/search' element={<TechnologySearch />} />
          <Route path='technology/catalogs' element={<TechnologyCatalogs />} />
          <Route path='technology/saved-report' element={<Dashboard />} />
          <Route path='technology/competitor-comparison' element={<Dashboard />} />
          <Route path='websites/search' element={<Dashboard />} />
          <Route path='websites/keywords-based-lists' element={<Dashboard />} />
          <Route path='websites/saved-report' element={<Dashboard />} />
          {/* <Route path='account' element={<Dashboard />} /> */}
          <Route path='account/profile' element={<Dashboard />} />
          <Route path='account/credits' element={<Dashboard />} />
          <Route path='account/price-plan' element={<Dashboard />} />
          <Route path='account/payments' element={<Dashboard />} />
          <Route path='account/orders' element={<Dashboard />} />
          <Route path='account/invoices' element={<Dashboard />} />
          <Route path='account/email-verifications' element={<Dashboard />} />
          <Route path='account/logout' element={<Dashboard />} />
        </Route>
        <Route path='*' element={<Error />} />
        <Route path={"/catalog/:slug"}  element={<ChartView />} />
      </Routes>
      {/* <RouterProvider router={router} /> */}
    </>
  );
}

export default App;
