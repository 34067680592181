import { useEffect, useState } from 'react';
import {EmailDiv} from './styled-emailRequest';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import {Link} from "react-router-dom";

const EmailRequest = ({email, error, emailHandler}: any) => {

    const [isLoading, setLoading] = useState<boolean>(false);
    const completed = email ? true : false;
    const [input, setInput] = useState<string>('')

    const handleClick = () => {
        setLoading(true);
        emailHandler(input);
    }

    const handleBack = () => {
        window.history.back();
    }

    useEffect(()=>{if(email || error.msg) setLoading(false);}, [email, error])

    return (
        <EmailDiv>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control 
                        type="email" 
                        placeholder={email ? email : "Enter email" }
                        value={input}
                        onChange={(e)=>setInput(e.target.value)}
                        style={{minWidth: "600px"}}
                    />
                </Form.Group>
                <div className="d-flex justify-content-center align-items-center col-md-12">
                    <div className="p-2">
                        {completed ? (
                            <>
                                <Button
                                    variant="primary"
                                    onClick={handleBack}
                                >
                                    Lookup another website
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="primary"
                                    disabled={isLoading || completed}
                                    onClick={(!isLoading && !completed) ? handleClick : undefined}
                                >
                                    {isLoading ? <Spinner animation="border" variant="light" /> : completed ? 'Done' : 'Receive Email Notification'}
                                </Button>
                            </>
                        )}

                    </div>

                    <div className="p-2">

                    <Link to={"/contact-us"}>
                        <Button
                            variant="outline-primary"
                        >
                            Contact Customer Support
                        </Button>
                    </Link>
                    </div>
                </div>

            </Form> 
            {error ? <div className='errorMsg'>{error.msg}</div> : ''}
        </EmailDiv>
    )
}

export default EmailRequest