import {Tabs, Tab, Button, Modal, Form} from 'react-bootstrap';
import {useContext, useEffect, useState} from "react";
import {emailRequest} from "../../../features/websiteData/websiteDataSlice";
import SharedDataContext from "../../../contexts/SharedDataContext";
import {Link} from "react-router-dom";

const Sidebar = (technologyData: any) => {
    const allGroups = useContext(SharedDataContext).groups;
    const [browserGroups, setBrowserGroups] = useState<any[]>([]);
    const handleExportClick = () => {
        const name = technologyData.name;
        window.open(`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/technology/search/${name}`);

    }

    useEffect(() => {
        console.log('technologyData', technologyData)

        const belongsCategories = technologyData.categories
        let relatedGroups: any[] = [];
        allGroups.forEach((group: any) => {
            group.categories.forEach((category: any) => {
                if(belongsCategories.some((item: any) => item.id === category.id)) {
                    relatedGroups.push(group)
                }
            })
        })

        setBrowserGroups(relatedGroups)
        console.log('relatedGroups', relatedGroups)

    }, [technologyData])

    return (
        <>
            <div className="col-md-12" style={{marginTop: "0px"}}>
                <div className="card p-3">
                    <h5 style={{color: 'black'}}>Technology Details</h5>
                        <span style={{fontWeight: "400", fontSize: "17px", fontFamily: "Nunito"}}>Get a list of {technologyData.websites_count} websites using {technologyData.name} which includes location information, contact details and more.</span>
                    <Button className="m-4" onClick={handleExportClick}>
                        Download Lead List
                    </Button>
                </div>

                <div className="card p-3 mt-4">
                    <h5 style={{color: 'black'}}>Browser Technology Groups</h5>
                    {browserGroups.map((group: any, index: number) => (
                        <>
                            {group.categories.map((category: any, index: number) => (
                                <>
                                    <div className="col-md-12 p-1">
                                        <Link to={`/catalog/${category.id}`}
                                           style={{ textDecoration: 'none'}}>
                                            <span style={{fontSize: "17px"}}>{category.name}</span>
                                        </Link>
                                    </div>
                                </>
                            ))}
                        </>
                    ))}

                </div>


            </div>

        </>
    )
}

export default Sidebar