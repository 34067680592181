import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';

export default function Error() {
  return (
      <Container className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
          <Row>
              <Col className="text-center">
                  <h1>Page not found!</h1>
                  <p>Sorry, but the page you were looking for could not be found. You can <a href='/'>return to our front page</a>, or <a href='/contact-us'>drop us a line</a> if you can't find what you're looking for.</p>

              </Col>
          </Row>
      </Container>
  )
}
