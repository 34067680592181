import { Footer } from '../../components/footer';
import { GettingStartedContent} from "../../components/GettingStartedContent";

const GettingStartedView = (props: any) => {
    return (
        <div id='wrapper'>
            <GettingStartedContent {...props}/>
            <Footer />
        </div>
    )
}

export default GettingStartedView;