import { Footer } from '../../components/footer';
import CompetitorComparisonFeaturePage from '../../components/ProductFeature/CompetitorComparisonFeaturePage';
import {Helmet} from "react-helmet";
const CompetitorComparisonFeaturePageView = () => {
    return (
        <div id='wrapper'>
            <Helmet>
                <title>Competitive Benchmarking| Competitor Analysis | Competitor Research | Competitor Marketing - Webrofiler</title>
                <meta name="description" content="Webrofiler offers comprehensive competitive benchmarking, competitor analysis, and research services to enhance your competitor marketing strategies." />
                <link rel="canonical" href="https://www.webrofiler.com/feature/competitor-comparison" />
            </Helmet>
            <CompetitorComparisonFeaturePage />
            <Footer />
        </div>  
    )
}

export default CompetitorComparisonFeaturePageView;