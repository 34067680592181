import React, {useContext, useEffect, useState, useRef} from 'react'
// import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Link, useLocation } from 'react-router-dom'
import {
    Navbar,
    Nav,
    Container,
    Button,
    Dropdown,
    NavDropdown,
    Spinner,
    PopoverBody,
    OverlayTrigger,
    Image
} from 'react-bootstrap';
import {StyledLink, StyledNavDropdown, StyledHeaderLink} from './styled-header';
import authContext from "../../contexts/AuthContext";
import {Box} from "react-bootstrap-icons";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');
function Header() {

    const { isLogin, user, logout } = useContext(authContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
    const menuRef = useRef<HTMLDivElement>(null);
    const [showProductDropdown, setShowProductDropdown] = useState(false);
    const [showResourcesDropdown, setShowResourcesDropdown] = useState(false);
    const [showContactUsDropdown, setShowContactUsDropdown] = useState(false);
    const [showWhyWebrofilerDropdown, setShowWhyWebrofilerDropdown] = useState(false);
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const resourceTimeRef = useRef<ReturnType<typeof setTimeout>>();
    const contactTimeRef = useRef<ReturnType<typeof setTimeout>>();
    const whyWebrofilerTimeRef = useRef<ReturnType<typeof setTimeout>>();

    const handleNavbarToggle = () => {
        setIsNavbarCollapsed(!isNavbarCollapsed);
    };

    const handleCollapsed = () => {
        console.log("handleCollapsed")
        setIsNavbarCollapsed(true);
    }
    const handleLogout = async () => {
        setIsLoading(true);
        await logout();
        setIsLoading(false);
    }

    const onLogin = () => {
        window.location.href = process.env.REACT_APP_DASHBOARD_URL + '/auth/login/basic';
    };
    const onCreateAccount = () => {
        window.location.href = process.env.REACT_APP_DASHBOARD_URL + '/auth/register/basic';
    }

    const onDashboard = () => {
        window.location.href = process.env.REACT_APP_DASHBOARD_URL + '/dashboards/home';

    }

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setIsNavbarCollapsed(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleShowProductDropdown = () => {
        setShowProductDropdown(true)
    }

    const handleCloseProductDropdown = () => {
        // Add a small delay before hiding the menu to allow the user to move the mouse to the first item
        timeoutRef.current = setTimeout(() => {
            setShowProductDropdown(false)
        }, 300);
    }

    const handleProductItemMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        setShowProductDropdown(true)
    };

    const handleShowResourcesDropdown = () => {
        setShowResourcesDropdown(true)
    }

    const handleCloseResourcesDropdown = () => {
        resourceTimeRef.current = setTimeout(() => {
            setShowResourcesDropdown(false)
        }, 300);
    }

    const handleResourcesItemMouseEnter = () => {
        clearTimeout(resourceTimeRef.current);
        setShowResourcesDropdown(true)
    }

    const handleShowContactUsDropdown = () => {
        setShowContactUsDropdown(true)
    }

    const handleCloseContactUsDropdown = () => {
        contactTimeRef.current = setTimeout(() => {
            setShowContactUsDropdown(false)
        }, 300);
    }

    const handleContactUsItemMouseEnter = () => {
        clearTimeout(contactTimeRef.current);
        setShowContactUsDropdown(true)
    }

    const handleShowWhyWebrofilerDropdown = () => {
        setShowWhyWebrofilerDropdown(true)
    }

    const handleCloseWhyWebrofilerDropdown = () => {
        whyWebrofilerTimeRef.current = setTimeout(() => {
            setShowWhyWebrofilerDropdown(false)
        }, 300);
    }

    const handleWhyWebrofilerItemMouseEnter = () => {
        clearTimeout(whyWebrofilerTimeRef.current);
        setShowWhyWebrofilerDropdown(true)
    }

    return (
        <div className="headerDiv">
        <Navbar bg="white" expand="lg" fixed="top" onToggle={handleNavbarToggle}
                className="headerDiv custom-navbar"
                expanded={!isNavbarCollapsed}
                ref={menuRef}
        >
            <Navbar.Brand style={{ marginLeft: "20px" }} href="/">
                <img
                    src={'/images/logo5.png'}
                    alt="Logo"
                    width="120"
                    className="d-inline-block align-top"
                />
                </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" style={{ marginRight: "20px" }}>
                <Nav className="mx-auto">
                    <StyledNavDropdown  id="basic-nav-dropdown"
                                       className="custom-nav-dropdown"
                                       style={{ marginLeft: '20px', marginRight: '10px' }}
                                       title={
                                           <span className="text-black my-auto">Products</span>
                                       }
                                        show={showProductDropdown}
                                        onMouseEnter={handleShowProductDropdown}
                                        onMouseLeave={handleCloseProductDropdown}

                    >
                        <NavDropdown.Item>
                                <StyledLink style={{paddingLeft:'0'}} to="/websitelookup" onMouseEnter={handleProductItemMouseEnter}  onClick={handleCollapsed}>
                                        <Image src={'/images/misc/weblookup-50.png'} alt="Logo" width="20" className="mr-3"/>
                                        <div>
                                            <div style={{ fontWeight: 'normal', fontSize: '14px' }}>Web Technology Lookup</div>
                                            <div style={{ fontWeight: 'normal', fontSize: '12px', color: '#8b949c' }}>Find out what websites are built with</div>
                                        </div>

                                </StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                                <StyledLink style={{paddingLeft:'0'}} to="/technologies" onMouseEnter={handleProductItemMouseEnter} onClick={handleCollapsed}>
                                    <Image src={'/images/misc/tech-50.png'} alt="Logo" width="20" className="mr-3" />
                                    <div>
                                        <div style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            Browse Technologies</div>
                                        <div style={{ fontWeight: 'normal', fontSize: '12px', color: '#8b949c' }}>Search for a technology or category</div>
                                    </div></StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink style={{paddingLeft:'0'}} to="/feature/detailed-website-profiles" onMouseEnter={handleProductItemMouseEnter} onClick={handleCollapsed}>
                                <Image src={'/images/misc/profile-50.png'} alt="Logo" width="20" className="mr-3" />

                                <div>
                                    <div style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                        Detailed Website Profiles</div>
                                    <div style={{ fontWeight: 'normal', fontSize: '12px', color: '#8b949c' }}>Technology stack, metadata and more</div>
                                </div>
                            </StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink style={{paddingLeft:'0'}} to="/feature/competitor-comparison" onMouseEnter={handleProductItemMouseEnter} onClick={handleCollapsed}>
                                <Image src={'/images/misc/comparison-50.png'} alt="Logo" width="20" className="mr-3" />

                                <div>
                                    <div style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                        Competitor Comparison</div>
                                    <div style={{ fontWeight: 'normal', fontSize: '12px', color: '#8b949c' }}>Compare and analyze two technologies</div>
                                </div>
                            </StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink style={{paddingLeft:'0'}} to="/feature/technology-market-share" onMouseEnter={handleProductItemMouseEnter} onClick={handleCollapsed}>
                                <Image src={'/images/misc/market-50.png'} alt="Logo" width="20" className="mr-3" />

                                <div>
                                    <div style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                        Technology Market Share</div>
                                    <div style={{ fontWeight: 'normal', fontSize: '12px', color: '#8b949c' }}>Reveals the distribution of technologies</div>
                                </div>
                            </StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink style={{paddingLeft:'0'}} to="/feature/keyword-based-lists" onMouseEnter={handleProductItemMouseEnter} onClick={handleCollapsed}>
                                <Image src={'/images/misc/keyword-50.png'} alt="Logo" width="20" className="mr-3" />

                                <div>
                                    <div style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                        Keyword Based Lists</div>
                                    <div style={{ fontWeight: 'normal', fontSize: '12px', color: '#8b949c' }}>Curated lists based on the indexing of keywords</div>
                                </div>
                            </StyledLink>
                        </NavDropdown.Item>
                    </StyledNavDropdown>
                    <StyledHeaderLink href="/pricing" style={{ marginLeft: "20px", marginRight: '10px' }} onClick={handleCollapsed}>
                        <span className="text-black" style={{fontWeight: 600}}>Pricing</span>
                    </StyledHeaderLink>
                    <StyledNavDropdown id="basic-nav-dropdown" style={{ marginLeft: "20px" }}
                                       title={
                                           <span className="text-black my-auto">Resources</span>
                                       }
                                        show={showResourcesDropdown}
                                        onMouseEnter={handleShowResourcesDropdown}
                                        onMouseLeave={handleCloseResourcesDropdown}
                    >
                        <NavDropdown.Item>
                            <StyledLink style={{paddingLeft:'0'}} to="/technologies" onMouseEnter={handleResourcesItemMouseEnter} onClick={handleCollapsed}>
                                <Image src={'/images/misc/tech-50.png'} alt="Logo" width="20" className="mr-3" />

                                <div>
                                    <div style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                    Browse Technologies
                                    </div>
                                <div style={{ fontWeight: 'normal', fontSize: '12px', color: '#8b949c' }}>Search for a technology or category</div>
                                </div>
                            </StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink style={{marginLeft: "20px"}} to="/getting-started" onMouseEnter={handleResourcesItemMouseEnter} onClick={handleCollapsed}>Getting Started</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink style={{marginLeft: "20px"}} to="/articles" onMouseEnter={handleResourcesItemMouseEnter} onClick={handleCollapsed}>Trends & Insights</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink style={{marginLeft: "20px"}} to="/faqs" onMouseEnter={handleResourcesItemMouseEnter} onClick={handleCollapsed}>FAQs</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink style={{marginLeft: "20px"}} to="/help-center" onMouseEnter={handleResourcesItemMouseEnter} onClick={handleCollapsed}>Get Help</StyledLink>
                        </NavDropdown.Item>
                    </StyledNavDropdown>
                    <StyledNavDropdown id="basic-nav-dropdown" style={{ marginLeft: "20px" }}
                                       title={
                                           <span className="text-black my-auto">Contact Us</span>
                                       }
                                        show={showContactUsDropdown}
                                        onMouseEnter={handleShowContactUsDropdown}
                                        onMouseLeave={handleCloseContactUsDropdown}
                    >
                        <NavDropdown.Item>
                            <StyledLink style={{marginLeft: "20px"}} to="/contact-us" onMouseEnter={handleContactUsItemMouseEnter} onClick={handleCollapsed}>Contact Sales</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink style={{paddingLeft:'0'}} to="/about-us" onMouseEnter={handleContactUsItemMouseEnter} onClick={handleCollapsed}>
                                <Image src={'/images/misc/aboutus-built-with-icon-30.png'} alt="Logo" width="20" className="mr-3" />
                                About Us
                            </StyledLink>
                        </NavDropdown.Item>
                    </StyledNavDropdown>
                    <StyledNavDropdown id="basic-nav-dropdown" style={{ marginLeft: "20px" }}
                                       title={
                                           <span className="text-black my-auto">Why Webrofiler</span>
                                       }
                                        show={showWhyWebrofilerDropdown}
                                        onMouseEnter={handleShowWhyWebrofilerDropdown}
                                        onMouseLeave={handleCloseWhyWebrofilerDropdown}
                    >
                        <NavDropdown.Item>
                            <StyledLink to="/what-is-webrofiler" onMouseEnter={handleWhyWebrofilerItemMouseEnter} onClick={handleCollapsed}>What’s Webrofiler?</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink to="/one-of-the-best-solution" onMouseEnter={handleWhyWebrofilerItemMouseEnter} onClick={handleCollapsed}>One of The Best Solution</StyledLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <StyledLink to="/how-webrofiler-continues-to-innovate" onMouseEnter={handleWhyWebrofilerItemMouseEnter} onClick={handleCollapsed}>How Webrofiler Continues to Innovate</StyledLink>
                        </NavDropdown.Item>

                    </StyledNavDropdown>                </Nav>
                    {isLogin ? (
                            <Nav className="ml-auto">
                                    {!isLoading ? (
                                        <>

                                            <NavDropdown id="basic-nav-dropdown" style={{ marginLeft: "0px" }}
                                                         title={(
                                                             <>
                                                                 <img src={'/images/avatar.png'}
                                                                 alt="Logo"
                                                                 width="40"
                                                                 className="d-inline-block "
                                                                 />
                                                                 {user?.email}
                                                             </>
                                                         )}
                                                         className="dashboard-nav-dropdown"
                                            >

                                                <NavDropdown.Item>
                                                    <StyledLink onClick={onDashboard}>
                                                        Dashboard
                                                    </StyledLink>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item>
                                                    <StyledLink onClick={handleLogout}>Logout</StyledLink>
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        </>
                                    ) : (
                                        <div>
                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    )}
                            </Nav>
                    ) : (
                            <div>
                                <Nav className="ml-auto">
                                    <Nav className="ml-2 my-2 my-lg-0">

                                <Button variant="outline-secondary" className="my-2" style={{ marginLeft: "20px" }} onClick={onLogin}>Sign in</Button>{' '}
                                <Button variant="primary" className="my-2" style={{ marginLeft: "20px" }} onClick={onCreateAccount}>Sign Up</Button>{' '}
                                    </Nav>
                                </Nav>
                            </div>
                        )}
            </Navbar.Collapse>
        </Navbar>

            <style>
                {`
        .headerDiv {
            margin-bottom: 0px;
          }

          @media (max-width: 879px) {
            .headerDiv {
              margin-bottom: 50px;
            }
          }
        
        .custom-navbar {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
            border-bottom: 1px solid #ececec; /* Add the bottom line here */
            /* Add any other styling you want for the Navbar */
            }
        
        `}
            </style>
        </div>
    )
}

export default Header