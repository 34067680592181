import React, {useEffect, useState} from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import { Footer } from '../../../components/footer'
import { Header } from '../../../components/header'
import { Link } from 'react-router-dom'
import { Autocomplete } from '../../../components/Autocomplete';
import {Button} from "react-bootstrap";
import {SpacerSingle, SecondSection} from "./styled-home";
import { Helmet } from 'react-helmet';

function Home() {
    const [isMobile, setIsMobile] = useState(false);

    // Check if the screen width is less than 992px (Bootstrap's lg breakpoint)
    const handleResize = () => {
        setIsMobile(window.innerWidth < 992);
    };

    // Add event listener to window resize event
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Helmet>
                <meta name="description" content="Webrofiler is a powerful web technology profiler tool that reveals the tech stack of any website. Uncover competitor insights, technographics data, and gain a competitive edge in lead generation and marketing." />
                <title>Find Out What a Website Is Built With | Website Built with Checker | Builtwith Alternative - Webrofiler</title>
                <link rel="canonical" href="https://www.webrofiler.com/" />
            </Helmet>

            <div id="wrapper"  style={{paddingTop: window.innerWidth > 992 ? '60px' : '0px', paddingBottom:'20px'}}>
                <div className="no-bottom no-top" id="content">
                    <div id="top"></div>
                    <section 
                        // className="no-top no-bottom vertical-center text-light"
                        className="no-top no-bottom text-light text-center"
                        //className="full-height no-top no-bottom vertical-center text-light"
                        style={{background: "#0a273d"}}
                        >
                        <div className="p-5"/>
                        <div className="container ">
                            {isMobile ? (
                                <div className="row align-items-center">
                                    <div className="col-lg-6 fadeInRight d-flex justify-content-center align-items-center "
                                         style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 60, textAlign: 'center'}}
                                    >
                                        {/*<img style={{ maxWidth: '550px', maxHeight: '400px' }} src={'/images/builtwith-500.png'}/>*/}
                                        <div className="col-md-11 col-lg-11" data-wow-delay="0s" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img className="img-fluid" src="images/builtwith-500.png" alt="" style={{width: '100%'}} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 fadeInRight m-auto mb-5" data-wow-delay=".5s" >
                                        <h1 style={{ marginTop: "8rem", color: "#ffffff !important" }} >Discover what a website is <br/>Built With</h1>
                                        <p style={{color: "#ffffff !important" }}>A website technology profiler to detect and identify website technology. Unravel web tech stack intricacies. Explore technographics data, lead generation and marketing secrets from your competitor's websites.</p>
                                        <div className="spacer-20"></div>
                                        <Autocomplete />
                                        {/*<input type="text" className="form-control mr-2" placeholder='Website URL, Technologies' name="emailName" id="" style={{ borderRadius: '10px', marginBottom: '10rem' }} />
                                    {<a href="#"><img src="images/misc/download-appstore.png" className="img-fluid"/></a>&nbsp;
                                    <a href="#"><img src="images/misc/download-playstore.png" className="img-fluid"/></a> */}
                                        <div className=""></div>
                                    </div>

                                    <SpacerSingle className="spacer-single"></SpacerSingle>
                                </div>
                            ) : (
                                <div className="row align-items-center">
                                    <div className="col-lg-6 fadeInRight m-auto mb-5" data-wow-delay=".5s" >
                                        <h1 style={{ marginTop: "8rem", color: "#ffffff" }} >Discover what a website is <br/>Built With</h1>
                                        <p className="lead" style={{color: "#ffffff"}}>A website technology profiler to detect and identify website technology. Unravel web tech stack intricacies. Explore technographics data, lead generation and marketing secrets from your competitor's websites.</p>
                                        <div className="spacer-20"></div>
                                        <Autocomplete />
                                        {/*<input type="text" className="form-control mr-2" placeholder='Website URL, Technologies' name="emailName" id="" style={{ borderRadius: '10px', marginBottom: '10rem' }} />
                                    {<a href="#"><img src="images/misc/download-appstore.png" className="img-fluid"/></a>&nbsp;
                                    <a href="#"><img src="images/misc/download-playstore.png" className="img-fluid"/></a> */}
                                        <div className=""></div>
                                    </div>
                                    <div className="col-lg-6 fadeInRight d-flex justify-content-center align-items-center "
                                         style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 60, textAlign: 'center'}}
                                    >
                                        {/*<img style={{ maxWidth: '550px', maxHeight: '400px' }} src={'/images/builtwith-500.png'}/>*/}
                                        <div className="col-md-11 col-lg-11" data-wow-delay="0s" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img className="img-fluid" src="images/builtwith-500.png" alt="" style={{width: '100%'}} />
                                        </div>
                                    </div>
                                    <SpacerSingle className="spacer-single"></SpacerSingle>
                                </div>
                            )}

                        </div>
                        <div className="p-5"/>

                    </section>

                    <SecondSection>
                        <div className="container" style={{ 'backgroundSize': 'cover' }}>
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3">
                                    <div className="text-center">
                                        <h2><span className="uptitle id-color">Our Services</span>How does it work?</h2>
                                        <div className="spacer-20"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" >
                                <div className="col-lg-4 col-md-6 mb40 wow fadeInUp" data-wow-delay="0s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color i-circle fa fa-laptop"></i>
                                        <div className="text" style={{height: "200px"}}>
                                            <h4 style={{ height: "60px" }}>Website Technology Analysis</h4>
                                            Identify website technology, explore technographics data, and unlock web tech stack insights with our swift, comprehensive tool.
                                        </div>
                                        <i className="wm fa fa-laptop"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb40 sq-item wow fadeInUp" data-wow-delay=".25s" >
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color-2 i-circle fa fa-paper-plane-o"></i>
                                        <div className="text" style={{height: "200px"}}>
                                            <h4 style={{ height: "60px" }}>Technologies Database</h4>
                                            Navigate an extensive, up-to-date technographics data resource, enhancing website technology insights and driving informed decisions.
                                        </div>
                                        <i className="wm fa fa-paper-plane-o"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb40 sq-item wow fadeInUp" data-wow-delay=".5s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color-3 i-circle fa fa-line-chart"></i>
                                        <div className="text" style={{height: "200px"}}>
                                            <h4 style={{ height: "60px" }}>Competitor analysis</h4>
                                            Uncover competitors' web tech stack secrets, leverage their strengths, and strategize your next move effectively.   <br/><br/>
                                        </div>
                                        <i className="wm i-circle fa fa-line-chart"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb40 wow fadeInUp" data-wow-delay="0s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color i-circle fa fa-object-group"></i>
                                        <div className="text" style={{height: "200px"}}>
                                            <h4 style={{ height: "60px" }}>Report Export</h4>
                                            Generate detailed website technology reports, effortlessly exported for further analysis and strategic planning.
                                        </div>
                                        <i className="wm fa fa-object-group"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb40 sq-item wow fadeInUp" data-wow-delay=".25s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color-2 i-circle fa fa-hdd-o"></i>
                                        <div className="text" style={{height: "200px"}}>
                                            <h4 style={{ height: "60px" }}>Advanced Search</h4>
                                            Harness specific keywords to identify website technology and explore targeted technographics data effortlessly.
                                        </div>
                                        <i className="wm fa fa-hdd-o"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb40 sq-item wow fadeInUp" data-wow-delay=".5s">
                                    <div className="feature-box f-boxed style-3">
                                        <i className="bg-color-3 i-circle fa fa-comments-o"></i>
                                        <div className="text" style={{height: "200px"}}>
                                            <h4 style={{ height: "60px" }}>Keyword & Catalog</h4>
                                            Utilize keyword-driven, categorized lists for effective website technology identification and strategic outreach.
                                        </div>
                                        <i className="wm i-circle fa fa-comments-o"></i>
                                    </div>
                                </div>
                                {/* <div className="col-md-12 text-center">
                                    <a className="btn-custom" href="#">More Features</a>
                                </div> */}
                            </div>
                        </div>
                    </SecondSection>
                    {/* <h2>
                                        Data security with<br />
                                        <span className="id-color">256-bit</span> encryption
                                    </h2> */}

                    <section id="section-banner" className="no-top">
                        <div className="container"
                             style={{  paddingLeft: '0px', paddingRight: '0px', marginRight: '0px', marginLeft: '0px', width: '100%', maxWidth: '100%' }}
                        >
                            <div className="row align-items-center"
                                 style={{ 'backgroundSize': 'cover',
                                     paddingLeft: '50px',
                                     paddingRight: '50px',
                                     paddingTop: '0px',
                                     paddingBottom: '20px',
                                     display: 'flex', justifyContent: 'center'
                                 }}
                            >
                                <div className="col-lg-5" data-wow-delay="0s" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img className="img-fluid" src="images/features/website-profiler.png" alt="" style={{width: '100%'}} />
                                </div>

                                <div className="col-lg-5 wow fadeInLeft" data-wow-delay="0s"
                                    style={{ paddingLeft: '40px'}}
                                >
                                    <h2>Website Profiler</h2>
                                    <p>
                                        Find out what websites are built with. Unearth the technological secrets behind any webpage. It's more than a website technology checker—it's a comprehensive tool to unravel the intricacies of a website's tech stack. From CMS to hosting providers, gain in-depth insights into what a website is built with.
                                    </p>
                                    <div className="spacer-half"></div>
                                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}`} >
                                    <Button variant='primary' >
                                            Get Started
                                        </Button>
                                    </a>
                                </div>

                                <div className="spacer-double"></div>
                            </div>
                            <div className="row align-items-center"
                                 style={{ 'backgroundSize': 'cover',
                                     background: '#ebf6ff',
                                     paddingLeft: '80px',
                                     paddingRight: '50px',
                                     paddingTop: '50px',
                                     paddingBottom: '50px',
                                     display: 'flex', justifyContent: 'center'
                                 }}
                            >
                                <div className="col-md-5" style={{ 'backgroundSize': 'cover' }} >
                                    <h2>Technology Lookup</h2>
                                    <p>
                                        Dive into the heart of a website, extracting detailed information about the technologies at work. Whether you're investigating a competitor's tech stack or curious about the underpinnings of a popular site, our website technology profiler offers quick and accurate analysis. From server software to CMS, analytics tools to widgets, gain a comprehensive understanding of any website's technologies.
                                    </p>
                                    <div className="spacer-half"></div>
                                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}`} >
                                        <Button variant='primary' >
                                            Get Started
                                        </Button>
                                    </a>
                                </div>

                                <div className="col-md-5" style={{ paddingLeft: '50px', 'backgroundSize': 'cover', display: 'flex', justifyContent: 'center' }}>
                                    <img src="images/features/technology-lookup.png" className="img-fluid" alt="" style={{width: '100%'}}/>
                                </div>
                            </div>
                            <div className="row align-items-center"
                                 style={{ 'backgroundSize': 'cover',
                                     paddingLeft: '50px',
                                     paddingRight: '50px',
                                     paddingTop: '50px',
                                     paddingBottom: '0px',
                                     display: 'flex', justifyContent: 'center'
                                 }}
                            >
                                <div className="col-lg-5" data-wow-delay="0s" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img className="relative img-fluid" src="images/features/competitor-website-analysis.png" alt="" style={{width: '100%'}}/>
                                </div>

                                <div className="col-lg-5 wow fadeInLeft" data-wow-delay="0s" style={{paddingLeft: '20px'}}>
                                    <h2>Competitor Website Analysis</h2>
                                    <p>
                                        Uncover why your competitor gets better results than you. Dissect your competitors' technology strategy, offering valuable insights into their chosen tech stack. Grasp their strengths and weaknesses, gain a competitive edge, and strategically navigate your business through the ever-evolving digital landscape.
                                    </p>
                                    <div className="spacer-half"></div>
                                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}`} >
                                        <Button variant='primary' >
                                            Get Started
                                        </Button>
                                    </a>
                                </div>

                                <div className="spacer-double"></div>
                            </div>
                            <div className="row align-items-center"
                                 style={{ 'backgroundSize': 'cover',
                                     background: '#ebf6ff',
                                     paddingLeft: '80px',
                                     paddingRight: '50px',
                                     paddingTop: '50px',
                                     paddingBottom: '50px',
                                     display: 'flex', justifyContent: 'center'
                                 }}
                            >
                                <div className="col-md-5" style={{ 'backgroundSize': 'cover' }}>
                                    <h2>Keyword Based Lists</h2>
                                    <p>
                                        Use your chosen keywords to identify websites matching specific search criteria. Whether you're interested in CMS checker tools or eCommerce platforms, our website technology profiler offers lists tailor-made for your business needs. Discover potential partners, competitors, or niche markets and elevate your business growth.
                                    </p>
                                    <div className="spacer-half"></div>
                                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}`} >
                                        <Button variant='primary' >
                                            Get Started
                                        </Button>
                                    </a>
                                </div>
                                <div className="col-md-5 " style={{ paddingLeft: '50px', 'backgroundSize': 'cover', display: 'flex', justifyContent: 'center' }}>
                                    <img src="images/features/keyword-based-lists.png" className="img-fluid" alt="" style={{width: '100%'}}/>
                                </div>
                            </div>
                            <div className="row align-items-center"
                                 style={{ 'backgroundSize': 'cover',
                                     paddingLeft: '50px',
                                     paddingRight: '50px',
                                     paddingTop: '50px',
                                     paddingBottom: '0px',
                                     marginBottom: '0px',
                                     display: 'flex', justifyContent: 'center'
                                 }}
                            >
                                <div className="col-lg-5 " data-wow-delay="0s">
                                    <img className="relative img-fluid" src="images/features/market-share.png" alt="" style={{width: '100%'}}/>
                                </div>

                                <div className="col-lg-5 wow fadeInLeft" data-wow-delay="0s" style={{paddingLeft: '20px'}}>
                                    <h2> Market Share</h2>
                                    <p>
                                        Uncover industry trends. Analyze vast technographics data, providing detailed insights into the usage of various website technologies across the internet. Understand the competitive landscape, see what's popular, what's gaining traction, and what's not. With our comprehensive data, predict trends, strategize effectively and capture a larger market share.
                                    </p>
                                    <div className="spacer-half"></div>
                                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}`} >
                                        <Button variant='primary' >
                                            Get Started
                                        </Button>
                                    </a>                                </div>

                                <div className="spacer-double"></div>
                            </div>

                        </div>
                    </section>

                    <section id="section-testimonial">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <h2><span className="uptitle id-color">Testimonial</span>What They Says</h2>
                                        <div className="spacer-20"></div>
                                    </div>

                                    {/* <div className="owl-carousel owl-theme wow fadeInUp" id="testimonial-carousel"> */}
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 mb40 wow fadeInUp"  data-wow-delay="0s">
                                            <div className="feature-box f-boxed style-3" style={{height: '340px'}}>
                                                <div className="text" >
                                                    "Webrofiler is like a knowledgeable guide who walks you through the intricacies of 'what a site is built with'. It articulates complex tech data into understandable language, providing an oral walkthrough of website technologies. For anyone keen on decoding the digital realm, Webrofiler is a fantastic companion."
                                                </div>
                                                <div className="mt-3"> <span> - Benjamin</span></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 mb40 wow fadeInUp"  data-wow-delay="0s">
                                            <div className="feature-box f-boxed style-3" style={{height: '340px'}}>
                                                <div className="text">
                                                    "As a tech analyst, Webrofiler has been a game-changer for us. Its in-depth website profiling capabilities and detailed technographics data are simply unparalleled. Highly recommended!"
                                                </div>
                                                <div className="mt-3"> <span> - Stephen</span></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 mb40 wow fadeInUp" data-wow-delay="0s">
                                            <div className="feature-box f-boxed style-3" style={{height: '340px'}}>
                                                <div className="text">
                                                    "It has a knack for distilling complex data into easily digestible, actionable insights, almost as if it's speaking to you. For anyone navigating the vast digital landscape, Webrofiler is your perfect oral guide, breaking down complexities into simple language."                                                </div>
                                                <div className="mt-3"> <span> - Sophia</span></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="section-fun-facts" className="pt60 pb60 text-light bg-color-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0s">
                                    <div className="de_count">
                                        <h3 className="timer" data-to="15425" data-speed="3000">0</h3>
                                        <span>WEBSITES POWERED</span>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay=".25s">
                                    <div className="de_count">
                                        <h3 className="timer" data-to="8745" data-speed="3000">0</h3>
                                        <span>Clients Supported</span>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                                    <div className="de_count">
                                        <h3 className="timer" data-to="235" data-speed="3000">0</h3>
                                        <span>Awards Winning</span>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay=".75s">
                                    <div className="de_count">
                                        <h3 className="timer" data-to="15" data-speed="3000">0</h3>
                                        <span>Years Experience</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Home