import { Footer } from '../../components/footer';
import { WebsiteLookup } from '../../components/websiteLookup';
import {useEffect} from "react";
import {Helmet} from "react-helmet";
const WebsiteLookupView = () => {

    return (
        <div id='wrapper'>
            <Helmet>
                <title>Website Technology Lookup | Builtwith Technology Lookup | Site Technology Lookup - Webrofiler</title>
                <meta name="description" content="Easily lookup website technology with Webrofiler. Discover the technologies powering websites, conduct competitor analysis, and gain valuable insights with our technology lookup tool." />
                <link rel="canonical" href="https://www.webrofiler.com/websitelookup" />
            </Helmet>
            <WebsiteLookup />
            <Footer />
        </div>  
    )
}

export default WebsiteLookupView;