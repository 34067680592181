import React, {Fragment, useEffect, useRef, useState} from "react";
import technologies from './technologies.json';
import {FormControl, InputGroup, Button, Form} from "react-bootstrap";
import {Input, Typeahead, MenuItem} from "react-bootstrap-typeahead";
import {useNavigate} from "react-router-dom";
import {extractWebsiteName} from "../utility/SearchWebsite";
import {getWebsiteSuggestions} from "../websiteLookupResult/utils/api";
import {FiletypeHeic, TypeH1} from "react-bootstrap-icons";
import axios from "axios";

const Autocomplete = ({websiteOnly=false}) => {
    const [selected, setSelected] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState("");
    const [websiteSuggestions, setWebsiteSuggestions] = useState([]);
    const [technologySuggestions, setTechnologySuggestions] = useState([]);
    const [currentSelection, setCurrentSelection] = useState(5);
    const navigate = useNavigate()

    const techItems = technologies.map((item) => {
        return {
            id: item.id,
            name: item.name,
            icon: item.icon
        }
    });

    const handleSelect = (selected) => {
        console.log("Selected:", selected);
        setSelected(selected);
        // Update the URL to the selected suggestion
        window.location.href = '/technology/compact/' + selected[0].id;
    };

    const menuStyle = {
        position: 'fixed',
        backgroundColor: '#fff',
        overflow: 'auto',
        maxHeight: '50vh',
        zIndex: 10000 // Set the zIndex to a high value
    };

    const handleSearch = () => {
        try {
            const {website, error} = extractWebsiteName(inputValue);
            if (error != null) {
                throw error;
            }
            const url = `/websitelookup/${website}`
            navigate(url)
        } catch (error) {
            console.error('Invalid URL:', error);
            setError('Please enter a valid URL.');
        }
    }

    let cancelToken

    const refreshWebsiteSuggestions = async (input, requestId) => {
        try {
            let tempId = Math.random().toString(36).substring(7);

            if(cancelToken) {
                cancelToken.cancel()
            }
            cancelToken = axios.CancelToken.source()

            // remove https and http
            let keyword = input.replace('https://', '')
            keyword = keyword.replace('http://', '')
            keyword = keyword.replace('www.', '')
            if( keyword.length < 2 ) {
                return
            }

            await getWebsiteSuggestions(keyword, cancelToken.token).then(
                (response) => {
                    if(response.data.success === true) {
                        setWebsiteSuggestions(response.data.websites.slice(0,5))
                    }
                }
            )
        }
        catch (error) {
            console.error('Fail to get website suggestions:', error);
        }
    }
    const handleUpdateSuggestions = async (input) => {
        if(!websiteOnly) {
            const techs = techItems.filter((item) => {
                return item.name.toLowerCase().includes(input.toLowerCase())
            }).slice(0,10)
            console.log('techs', techs)
            setTechnologySuggestions(techs)

        }
        else {
            setTechnologySuggestions([])
        }

        if(input.length < 2) {
            setWebsiteSuggestions([])
            return
        }
        try {

            refreshWebsiteSuggestions(input, null)

        } catch (error) {
            console.error('Fail to get website suggestions:', error);
        }
    }

    const isWebsiteUrl = (input) => {
        // Regular expression that matches common website URL formats
        const {website, error} = extractWebsiteName(input);
        return error == null;
    };

    useEffect(() => {
        handleUpdateSuggestions(inputValue)
    },[inputValue])

    const renderMenu = (results, menuProps) => {
        if(inputValue == null || inputValue === '') {
            return null
        }

        if(websiteSuggestions.length === 0 && technologySuggestions.length === 0) {
            return <></>
        }
        return (
            <div {...menuProps} className="rbt-menu dropdown-menu show"
                 style={{ maxHeight: "600px",
                     width: "100%",
                     position: "absolute",
                     overflowY: "scroll",
                }}
            >
                {websiteSuggestions.length > 0 && (
                    <>
                        <div>
                            <span className='ml-3' style={{color: 'lightgray'}}>Suggested Websites</span>
                        </div>
                        {websiteSuggestions.map((website, index) => (
                            <a aria-label="2B Advice" aria-selected="false" id="autocomplete-item-0" role="option"
                               className="dropdown-item"
                               href={'/websitelookup/' + website}
                               style={{color: '#000',
                                   background: currentSelection == index ? '#e9ecef' : '#fff',
                            }}
                                 onMouseEnter={() => handleMouseEnter(index)}
                            >
                                <div style={{color: '#000'}}>{website}</div>
                            </a>
                        ))}

                        {technologySuggestions.length > 0 && (
                            <></>
                        // <div className="dropdown-divider lightgrey" style={{color: 'lightgray'}}></div>
                            )}
                    </>
                )}

                {technologySuggestions.length > 0 && (
                    <>
                        <div>
                            <span className='ml-3' style={{color: 'lightgray'}}>Suggested Technologies</span>
                        </div>
                        {technologySuggestions.map((tech, index) => (
                            <a aria-label="2B Advice" aria-selected="false" id="autocomplete-item-0" role="option"
                                 className="dropdown-item"
                               href={"/technology/compact/" + tech.id}
                               style={{color: '#fff',
                                   background: currentSelection - websiteSuggestions.length == index ? '#e9ecef' : '#fff',
                               }}
                                    onMouseEnter={() => handleMouseEnterTech(index)}
                            >                                <div className="d-flex align-items-center">
                                    <img src={"images/icons/" + tech.icon} style={{ 'height': '24px', 'width': '24px' }}/>
                                    <div className="ml-2 pt-1 pb-1" style={{color: '#000'}}>
                                        {tech.name}
                                    </div>
                                </div>

                            </a>
                        ))}
                    </>
                    )}
            </div>
        )
    };

    function onKeyPress(event) {
        if (event.keyCode === 38 /* Enter */) {
            event.preventDefault();
            console.log('up')
            const total = websiteSuggestions.length + technologySuggestions.length
            if(total == 0 ) {
                return
            }
            if(currentSelection == null) {
                setCurrentSelection(total - 1)
            }
            else if(currentSelection > 0) {
                setCurrentSelection(currentSelection - 1)
            }
            else {
                setCurrentSelection(total - 1)
            }
        }
        else if (event.keyCode === 40 /* Down */) {
            const total = websiteSuggestions.length + technologySuggestions.length
            console.log('down', currentSelection, total, websiteSuggestions.length, technologySuggestions.length)
            if(total == 0 ) {
                return
            }
            if(currentSelection == null) {
                setCurrentSelection(0)
            }
            else if(currentSelection < total - 1) {
                console.log('increase selection to', currentSelection + 1)
                setCurrentSelection(currentSelection + 1)
            }
            else {
                setCurrentSelection(0)
            }
        }
        else if (event.keyCode === 13 /* Enter */) {
            if(currentSelection == null) {
                window.location.href = '/websitelookup/' + inputValue
            }
            else {
                if(currentSelection < websiteSuggestions.length) {
                    window.location.href = '/websitelookup/' + websiteSuggestions[currentSelection]
                }
                else if(currentSelection < websiteSuggestions.length + technologySuggestions.length) {
                    window.location.href = '/technology/compact/' + technologySuggestions[currentSelection - websiteSuggestions.length].id
                }
            }

        }
        else {
            setCurrentSelection(null)
        }
    }

    const handleMouseEnter = (index) => {
        setCurrentSelection(index)
    }

    const handleMouseEnterTech = (index) => {
        setCurrentSelection(index + websiteSuggestions.length)
    }

    const handleMouseLeave = () => {
        setCurrentSelection(null)
    }

    return (
        <div>
            <div style={{ width: "100%" }} className="flex-fill">
                <div className="invalid-feedback" style={{ display: 'block', clear: 'both', marginBottom: '10px' }}>
                    <span>{error}</span>
                </div>
            </div>

            <div className="d-flex justify-content-center align-items-center">
                <div style={{ width: "100%" }} className="flex-fill">
                    <Typeahead
                        className="w-auto mr-2"
                        id="autocomplete"
                        labelKey="name"
                        onChange={handleSelect}
                        options={techItems}
                        placeholder="Enter a website domain or technology name"
                        selected={selected}
                        inputProps={{ style: { width: "100%" } }} // Set the width of the input to 100%
                        style={{ width: "100%" }} // Set the width of the Typeahead to 100%
                        onInputChange={(input) => {
                            setSelected([]);
                            setInputValue(input);
                        }}
                        onBlur={() => {
                            setSelected([]);
                        }}
                        onKeyDown={onKeyPress}
                        onSelect={handleSelect}

                        menuStyle={menuStyle}
                        emptyLabel={`Search website: ${inputValue}`} // Set the empty label to "Search website" with the input value
                        renderMenu={(results, menuProps) => renderMenu(results, menuProps)}


                    />
                </div>

                <Button variant="primary" onClick={handleSearch} disabled={!isWebsiteUrl(inputValue)}
                        style={{height: "40px"}}
                >Search</Button>
            </div>

        </div>

    );
};

export default Autocomplete;
