import { Footer } from '../../components/footer';
import DetailedWebsiteProfilesFeaturePage from '../../components/ProductFeature/DetailedWebsiteProfilesFeaturePage';
import {Helmet} from "react-helmet";
const DetailedWebsiteProfilesFeaturePageView = () => {
    return (
        <div id='wrapper'>
            <Helmet>
                <title>Detailed Website Profiles | Business Website Profile | Website Profiler - Webrofiler</title>
                <meta name="description" content="Webrofiler provides detailed website profiles and business website profiles to help you gain valuable insights into your online presence. Understand your website's performance better decision-making." />
                <link rel="canonical" href="https://www.webrofiler.com/feature/detailed-website-profiles" />

            </Helmet>

            <DetailedWebsiteProfilesFeaturePage />
            <Footer />
        </div>  
    )
}

export default DetailedWebsiteProfilesFeaturePageView;