import {useEffect, useState} from 'react';
import { Main } from './styled-pricing';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import Upgrade from '../websiteLookupResult/upgrade/Upgrade';
import Accordion from 'react-bootstrap/Accordion';
import {Button, Form} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'Pricing', path: '/pricing'},
]

const Pricing = () => {
    const [monthly, setMonthly] = useState(true)
    const [productType, setProductType] = useState('monthly')
    const [faqData, setFAQData] = useState<string>();
    const [activeGroup, setActiveGroup] = useState<any>();
    const Input = ({ searchWebsite, placeholder, type='text', disabled=false }: any) => {
    const [inputValue, setInputValue] = useState('')

    function inputHandler({key, target: {value}}: any){if(key === 'Enter') searchWebsite(value)}

    return ( 
      <div className='inputDiv'>
        <Form.Control 
          value={inputValue} 
          onChange={({target: {value}})=>setInputValue(value)} 
          onKeyUp={(e)=>inputHandler(e)}
          type={type} 
          placeholder={placeholder} 
          disabled={disabled}  
        />
        <Search />
      </div>
    )
  }

  const navigate = useNavigate()

  function searchWebsite(value: string){if(value.length) navigate(value)}

  const toggleBillingPeriode = () => {
      setMonthly(!monthly)
      setProductType(monthly ? 'yearly' : 'monthly')
  }

    const fetchJson = () => {
        fetch(process.env.REACT_APP_FAQS_URL as string)
            .then(response => {
                return response.json();
            }).then(data => {
            setFAQData(data);
            setActiveGroup("2");
        }).catch((e: Error) => {
            console.log(e.message);
        });
    }

    const FAQGroupItems = (props: any) => {
        return (
            <div>
                {
                    props.data && props.data.map ((group: any) =>(
                        <div key={group.id} style={{ display: activeGroup === group.id ? 'block' : 'none' }}>
                            <Accordion>
                                {
                                    group.items && group.items.map ((item: any) =>(
                                        <Accordion.Item key={item.id} eventKey={item.id} style={{marginTop: 0, marginBottom: 10}}>
                                            <Accordion.Header>{item.title}</Accordion.Header>
                                            <Accordion.Body>{item.content}</Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>
                    ))
                }
            </div>
        );
    }
    useEffect(() => {
        fetchJson()
    },[])


    return (
    <Main>
        <div className='container websiteDetails' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
            <RouteCreator routes={routesInit} />
            <h1 className="text-center">Choose your plan</h1>

            <div className="container mt-4">
				<div className="row">
                    <div className="col text-center">
                        <div className="switch-set">
                            <div>Billing Type:</div>
                            <div>Monthly</div>
                            <div><input id="sw-1" className="switch" type="checkbox" onClick={toggleBillingPeriode} /></div>
                            <div>Yearly (17% off)</div>
                            <div className="spacer-20"></div>
                        </div>           
                    </div>
                </div>

                <div className="item pricing">
                    <div className="container">
                        <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="pricing-s1 mb30">
                                    <div className="top">
                                        <h2>Standard</h2>
                                        <p className="plan-tagline">Limited access</p>
                                    </div>
                                    <div className="mid text-light bg-grey">
                                        <p className="price text-black">
                                            <span className="currency">usd$</span>
                                            <span className="m opt-1">99</span>
                                            <span className="y opt-2">83</span>
                                            <span className="month">/month</span>
                                        </p>               
                                    </div>
                                    
                                    <div className="bottom">

                                        <ul>
                                            <li><i className="fa fa-check"></i>Technologies: 2</li>
                                            <li><i className="fa fa-check"></i>Websites: 2</li>
                                            <li><i className="fa fa-check"></i>Metadata Export</li>
                                            <li><i className="fa fa-check"></i>Advanced Filtering</li>
                                            <li><i className="fa fa-check"></i>System Logins: 1</li>
                                            <li><i className="fa fa-check"></i>Cancel at Anytime</li>
                                        </ul>
                                    </div>
                                    
                                    <div className="action">
                                        <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan?default=standard_` + productType} >
                                            <Button variant="primary"  >Sign Up Now</Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="pricing-s1 mb30">
                                    <div className="top">
                                        <h2>Professional</h2>
                                        <p className="plan-tagline">Full access</p>
                                    </div>
                                        <div className="mid text-light bg-color-2">
                                        <p className="price">
                                            <span className="currency">usd$</span>
                                            <span className="m opt-1">199</span>
                                            <span className="y opt-2">167</span>
                                            <span className="month">/month</span>
                                        </p>
                                    </div>
                                    <div className="bottom ">
                                        <ul>
                                            <li><i className="fa fa-check"></i>Technologies: Unlimited</li>
                                            <li><i className="fa fa-check"></i>Websites: Unlimited</li>
                                            <li><i className="fa fa-check"></i>Metadata Export</li>
                                            <li><i className="fa fa-check"></i>Advanced Filtering</li>
                                            <li><i className="fa fa-check"></i>System Logins: 1</li>
                                            <li><i className="fa fa-check"></i>Cancel at Anytime</li>

                                        </ul>
                                    </div>

                                    <div className="action">
                                        <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan?default=professional_` + productType} >
                                            <Button variant="primary"  >Sign Up Now</Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="pricing-s1 mb30">
                                    <div className="top">
                                        <h2>Enterprise</h2>
                                        <p className="plan-tagline">Full access</p>
                                    </div>
                                    <div className="mid text-light bg-grey">
                                        <p className="price text-black">
                                            <span className="currency">usd$</span>
                                            <span className="m opt-1">399</span>
                                            <span className="y opt-2">333</span>
                                            <span className="month">/month</span>
                                        </p>
                                    </div>

                                    <div className="bottom">

                                        <ul>
                                            <li><i className="fa fa-check"></i>Technologies: Unlimited</li>
                                            <li><i className="fa fa-check"></i>Websites: Unlimited</li>
                                            <li><i className="fa fa-check"></i>Metadata Export</li>
                                            <li><i className="fa fa-check"></i>Advanced Filtering</li>
                                            <li><i className="fa fa-check"></i>System Logins: 5</li>
                                            <li><i className="fa fa-check"></i>Cancel at Anytime</li>

                                        </ul>
                                    </div>

                                    <div className="action">
                                        <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan?default=enterprise_` + productType} >
                                            <Button variant="primary"  >Sign Up Now</Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="pricing-s1 mb30">
                                    <div className="top">
                                        <h2>Global</h2>
                                        <p className="plan-tagline">Full access</p>
                                    </div>
                                    <div className="mid text-light bg-grey">
                                        <p className="price text-black">
                                            <span className="currency">usd$</span>
                                            <span className="m opt-1">795</span>
                                            <span className="y opt-2">667</span>
                                            <span className="month">/month</span>
                                        </p>
                                    </div>

                                    <div className="bottom">

                                        <ul>
                                            <li><i className="fa fa-check"></i>Technologies: Unlimited</li>
                                            <li><i className="fa fa-check"></i>Websites: Unlimited</li>
                                            <li><i className="fa fa-check"></i>Metadata Export</li>
                                            <li><i className="fa fa-check"></i>Advanced Filtering</li>
                                            <li><i className="fa fa-check"></i>System Logins: Unlimited</li>
                                            <li><i className="fa fa-check"></i>Cancel at Anytime</li>

                                        </ul>
                                    </div>

                                    <div className="action">
                                        <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan?default=global_` + productType} >
                                            <Button variant="primary"  >Sign Up Now</Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-lg-4 col-md-6 col-sm-12">*/}
                            {/*    <div className="pricing-s1 mb30">*/}
                            {/*        <div className="top">*/}
                            {/*            <h2>Business</h2>*/}
                            {/*            <p className="plan-tagline">Best for organization</p>*/}
                            {/*        </div>*/}
                            {/*        <div className="mid text-light bg-color-3">*/}
                            {/*            <p className="price">*/}
                            {/*                <span className="currency">$</span>*/}
                            {/*                <span className="m opt-1">24.99</span>*/}
                            {/*                <span className="y opt-2">16.49</span>*/}
                            {/*                <span className="month">p/mo</span>*/}
                            {/*            </p>     */}
                            {/*        </div>*/}
                            {/*        <div className="bottom">*/}
                            {/*            <ul>*/}
                            {/*                <li><i className="fa fa-check"></i>6x Processing Power</li>*/}
                            {/*                <li><i className="fa fa-check"></i>Unlimited Website</li>*/}
                            {/*                <li><i className="fa fa-check"></i>100GB Disk Space</li>*/}
                            {/*                <li><i className="fa fa-check"></i>Easy Website Builder</li>*/}
                            {/*                <li><i className="fa fa-check"></i>99.9% Uptime Guarantee</li>*/}
                            {/*                <li><i className="fa fa-check"></i>24/7 Customer Support</li>*/}
                            {/*            </ul>*/}
                            {/*        </div>*/}
                            {/*        */}
                            {/*        <div className="action">*/}
                            {/*            <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`} className="btn-custom">Sign Up Now</a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className="" style={{background: '#ebf7ff'}}>
            <div className="row" >
                <div className="col text-center mt-5">
                    <div className="switch-set">
                        <h2>Search more, get more</h2>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-10 row justify-content-center">
                <div className="col-lg-5 col-md-5 col-sm-12 d-flex justify-content-center mt-5 ">
                    <img src={'images/Icon-5-100.png'} style={{maxWidth: 50, maxHeight: 50, marginTop: 4}}/>
                    <div className="ml-4" style={{lineHeight: 1}}>
                        <h5 style={{marginBottom: '2px', marginTop: '0px'}}>Search more websites</h5>
                        <span style={{fontSize: 14, lineHeight: 0.6, marginTop: 0 }}>Explore endless insights by analyzing multiple websites' technology stacks, identifying competitors, and refining your digital strategy.</span>
                    </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 d-flex justify-content-center mt-5 ">
                    <img src={'images/Icon-8-100.png'} style={{maxWidth: 50, maxHeight: 50, marginTop: 4}}/>
                    <div className="ml-4" style={{lineHeight: 1}}>
                        <h5 style={{marginBottom: '2px', marginTop: '0px'}}>Search more technologies</h5>
                        <span style={{fontSize: 14, lineHeight: 0.6, marginTop: 0}}>Discover a wider range of technologies powering websites, gain competitive intelligence, and supercharge your online strategy.</span>
                    </div>
                </div>
                </div>
            </div>
            <div className="row justify-content-center pb-5">
                <div className="col-lg-10 row justify-content-center">
                <div className="col-lg-5 col-md-5 col-sm-12 d-flex justify-content-center mt-5 ">
                    <img src={'images/Icon-11-100.png'} style={{maxWidth: 50, maxHeight: 50, marginTop: 4}}/>
                    <div className="ml-4" style={{lineHeight: 1}}>
                        <h5 style={{marginBottom: '2px', marginTop: '0px'}}>Export data</h5>
                        <span style={{fontSize: 14, lineHeight: 0.6, marginTop: 0}}>Easily export website technology profiles and analytics data into CSV files for seamless integration into your workflow.</span>
                    </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 d-flex justify-content-center mt-5 ">
                    <img src={'images/filter-100.png'} style={{maxWidth: 50, maxHeight: 50, marginTop: 4}}/>
                    <div className="ml-4" style={{lineHeight: 1}}>
                        <h5 style={{marginBottom: '2px', marginTop: '0px'}}>Advanced filtering</h5>
                        <span style={{fontSize: 14, lineHeight: 0.6, marginTop: 0}}>Utilize advanced filtering options to refine search results, target specific technologies, and optimize your competitive analysis.
                </span>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="pl-5 pr-5" style={{background: '#ffffff'}}>
            <div className="row" >
                <div className="col text-center mt-5">
                    <div className="switch-set">
                        <h2>Frequently asked questions</h2>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-lg-12 col-12 align-items-center justify-content-center mt-4 mb-4">
                <div className="row">
                    <FAQGroupItems data={faqData} />
                </div>
                <div style={{width: '100%'}}>
                    <div className="d-flex align-items-center justify-content-center">
                        <a href={"/help-center"}>
                        <Button variant='outline-info' style={{color: "black", borderColor: "black"}}>Need more help?</Button>
                        </a>
                    </div>
                </div>
            </div>


        </div>
    </Main>
  )
}

export default Pricing    