import { useState, useEffect } from 'react';
import { Main } from './styled-productfeature';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Button} from "react-bootstrap";

export const routesInit = [
  {name: 'Home', path: '/'},
  {name: 'Keyword Based Lists', path: '/feature/keyword-based-lists'},
]

const KeywordBasedListsFeaturePage = () => {

  useEffect(() => {

},[])

  return ( 
    <Main>
        <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
            <RouteCreator routes={routesInit} />
            <div className='content-container'>
                <h1 className="text-center">Keyword Based Lists</h1>
                <div className="descDiv">
                  <p className='pageDescription'>Keyword-Based Lists provide curated lists based on the indexing of keywords found on the homepages of websites. These lists offer valuable information about the keywords being used by various websites, allowing users to explore specific topics, trends, or industries. By utilizing these keyword-based lists, users can gain insights into popular keywords and tailor their own strategies accordingly, whether it's for content creation, SEO optimization, or market research.




                  </p>
                </div>
                <div>
                    <img className="screenshot" src="/images/features/keyword-based-list.png" alt="screenshot1"></img>
                </div>
                <div className="signupDiv">
                    <a href={`${process.env.REACT_APP_DASHBOARD_SIGN_UP_URL}?backTo=/dashboards/account/price-plan`}>
                        <Button variant='primary' size='lg'>Sign Up for Free</Button>
                    </a>
                </div>
            </div>
        </div>
    </Main>
  )
}

export default KeywordBasedListsFeaturePage