import { Footer } from '../../components/footer';
import { WebsiteLookupResult } from '../../components/websiteLookupResult';
import {useEffect} from "react";

const WebsiteLookupResultView = () => {
    useEffect(() => {
        document.title = "Website Technology Lookup | Builtwith Technology Lookup | Site Technology Lookup - Webrofiler";
    }, []);
    return (
        <div id='wrapper'>
            <WebsiteLookupResult />
            <Footer />
        </div>  
    )
}

export default WebsiteLookupResultView;