import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react';
import RouteCreator from '../technologyProfileCompact/utils/routeCreator';
import {Link, useParams} from 'react-router-dom';
import {Form,Button} from "react-bootstrap";
import {Search} from "react-bootstrap-icons";
import axios from "../../services/commonHttp";
export const routesInit = [
    {name: 'Home', path: '/'},
    {name: 'Privacy', path: '/privacy'},
]

const TermsContent = () => {

    return (
        <>
            <div className='container v-application' style={{paddingTop: window.innerWidth > 992 ? '100px' : '20px', paddingBottom:'20px'}}>
                <RouteCreator routes={routesInit} />
                <h1>Webrofiler Privacy Policy</h1>
                {/*<p className='pageDescription'>You can use a quick contact form located on this page to ask a question about our services. We would be happy to answer your questions or offer any help.*/}
                {/*</p>*/}
                <div className="container-fluid ">
                    <div className="row offset-md-1 mt-5 mb-5">
                        <h2>1. INTRODUCTION</h2>
                        <p>
                            Alive Software Inc. (“we,” “us” or “our”) takes your privacy seriously. This Privacy Policy (“Privacy Policy”) helps explain how we collect, use, store, and protect your information when you use our website (“Website”), mobile application and any services provided through this application (the “App”, “our App”).
                        </p>
                        <p>
                            When we refer to personal data (or personal information) we mean any information of any kind relating to an identified or identifiable natural person. It is a natural person who can be identified directly or indirectly, in particular by reference to an identification number or to one or more factors specific to his or her physical, physiological, mental, economic, cultural or social status. By using our App, you are consenting to our processing of your information in ways set out in this Privacy Policy.
                        </p>
                        <p>
                            We may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.
                        </p>
                        <h2>2. INFORMATION WE COLLECT</h2>
                        <p>
                            We receive or collect information when we operate and provide our App, including when you install, access, or use our App. There are two general categories of information we may process.
                        </p>
                        <h3>2.1 Information you provide us and that relates directly to you.</h3>
                        <p>
                            This information is necessary for the performance of the contract between you and us.
                        </p>
                        <h3>2.2 Information that is processed automatically and does not directly identify you</h3>
                        <p>
                            When you use our app, some information about your device and your user behavior may be processed automatically. This information is generally non-personal, and we may access it only in aggregated form. We process this information on the ground of our legitimate interest in improving our Website and giving our users the best experience.
                        </p>
                        <p>
                            We may collect and store any personal information you enter on the website or provide us in some other manner, including personal information that may be contained in any feedback communication. This includes your name, e-mail address, and other information you decide to submit us.
                        </p>
                        <p>
                            Name<br/>
                            Contact information including email address, url and telephone number<br/>
                            Demographic information such as postcode, preferences and interests<br/>
                            Contacts, call logs and recordings of calls<br/>
                            Other information relevant to customer offers<br/>
                            We automatically collect certain Information from your device when you use our App including Information about your device, carrier and other technical data.
                        </p>
                        <h2>3. THE PURPOSES OF COLLECTING THE INFORMATION</h2>
                        <p>
                            We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
                        </p>
                        <ul>
                            <li>Provide you the service, App and customer support you request;</li>
                            <li>Verify your account;</li>
                            <li>Resolve disputes and troubleshoot problems;</li>
                            <li>Prevent and investigate potentially prohibited or illegal activities, and/or violations of our posted Terms;</li>
                            <li>Customize, measure, and improve the App and the content and layout of our App;</li>
                            <li>Deliver targeted marketing and service update notices based on your communications preferences. We will not use your information for purposes other than those purposes we have disclosed to you, without your permission.</li>
                        </ul>
                        <h2>4. SHARING YOUR INFORMATION</h2>
                        <p>
                            We will never sell or rent your information to third parties.
                        </p>
                        <p>
                            We may share your information from tools like log files, and device identifiers and location data, with third-party organizations that provide automatic data processing technologies for the App. We do not control or influence these third parties’ tracking technologies or how they may be used.
                        </p>
                        <p>
                            We may also share certain information such as cookie data with third-party advertising partners. This information allows third-party ad networks, inter alia, to deliver targeted advertisements that they believe will be of most interest to you.
                        </p>
                        <h2>5. SECURITY</h2>
                        <p>
                            We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                        </p>
                        <h2>6. CHILDREN’S PRIVACY</h2>
                        <p>
                            These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take necessary actions.
                        </p>
                        <h2>7. CHANGES TO THIS PRIVACY POLICY</h2>
                        <p>
                            We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
                        </p>
                        <h2>8. LINKS TO OTHER WEBSITES</h2>
                        <p>
                            Our website may contain links to enable you to visit other websites of interest easily. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
                        </p>
                        <h2>9. CONTACT US</h2>
                        <p>
                            If you have any questions about this Privacy Policy, please do not hesitate to contact us at support@webrofiler.com.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsContent