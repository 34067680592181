import React, { createContext, useEffect, useState } from 'react';
import {getAllGroups} from "../components/websiteLookupResult/utils/api";
import loadCountries from '../data/countriesLoader';

interface SharedDataContextType {
    countries: any[]; // Define the correct type for your countries data
    categories: any[];

    groups: any[];
    technologies: any[];

    keywords: any[];
}

const SharedDataContext = createContext<SharedDataContextType>({
    countries: [],
    categories: [],
    groups: [],
    technologies: [],
    keywords: [],
});

interface SharedDataContextProviderProps {
    children: React.ReactNode;
}

export const SharedDataProvider: React.FC<SharedDataContextProviderProps> = ({ children }) => {
    const [countries, setCountries] = useState<any[]>([]); // Define the correct type for your countries data
    const [categories, setCategories] = useState<any[]>([]);
    const [groups, setGroups] = useState<any[]>([]);
    const [technologies, setTechnologies] = useState<any[]>([]);
    const [keywords, setKeywords] = useState<any[]>([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const countriesData = await loadCountries();
                setCountries(countriesData);

                const allGroups = await getAllGroups();
                console.log('allGroups', allGroups.data);
                setGroups(allGroups.data);

                const categories = allGroups.data.reduce((acc: any[], group: { categories: any[]; }) => {
                    group.categories.forEach((category) => {
                        if (!acc.some((item) => item.id === category.id)) {
                            acc.push(category);
                        }
                    });
                    return acc;
                }, []);
                // console.log('categories', categories);
                setCategories(categories);

                // const technologies = await getAllTechnologies();
                // setTechnologies(technologies.data);

            } catch (error) {
                console.error('Error loading countries:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <SharedDataContext.Provider value={{ countries, groups, categories, technologies, keywords }}>
            {children}
        </SharedDataContext.Provider>
    );
};

export default SharedDataContext;
